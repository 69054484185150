import React, { Fragment } from "react";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    ColumnValueType,
    NumberFormat,
    TableColumn,
    TableList,
    VerticalBarChart,
} from "../../Controls";
import {
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,
    Dropdown,
    IDropdownOption,
    Label,
    Stack,
} from "@fluentui/react";
import { IDataItem, Typename } from "../../DataContract";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { FullScreen } from "../Common/FullScreen";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { exportGeneralMetrics } from "../../Utils/ExportFile";
import { getColorByIndex } from "../../Utils";
import { TransformedDataItem } from "./OcrDataContract";
import { LargeModelBaseItem } from "./LargeModelByImage";

// prettier-ignore
const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",                  name: "Category",                     fieldName: "category",                 isKey: true,   valueType: ColumnValueType.String,  minWidth: 100,  maxWidth: 200,  isResizable: true, distinctStr: true },
    { key: "word_sort_ratio",           name: "word_sort_ratio",              fieldName: "word_sort_ratio",          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "weight",                    name: "weight",                       fieldName: "weight",                   isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 200,  isResizable: true, },
    { key: "inserts",                   name: "inserts",                      fieldName: "inserts",                  isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, },
    { key: "deletes",                   name: "deletes",                      fieldName: "deletes",                  isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, },
    { key: "subs",                      name: "subs",                         fieldName: "subs",                     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "completion_tokens",         name: "completion_tokens",            fieldName: "completion_tokens",        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 200,  isResizable: true, },
    { key: "failed_images",             name: "failed_images",                fieldName: "failed_images",            isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, },
    { key: "failed_rate",               name: "failed_rate (%)",              fieldName: "failed_rate",              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
    { key: "p50_latency",               name: "p50_latency",                  fieldName: "p50_latency",              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "p90_latency",               name: "p90_latency",                  fieldName: "p90_latency",              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "p95_latency",               name: "p95_latency",                  fieldName: "p95_latency",              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "p99_latency",               name: "p99_latency",                  fieldName: "p99_latency",              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "prompt_tokens",             name: "prompt_tokens",                fieldName: "prompt_tokens",            isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 200,  isResizable: true, },
    { key: "succeeded_images",          name: "succeeded_images",             fieldName: "succeeded_images",         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 250,  isResizable: true, },
    { key: "total_images",              name: "total_images",                 fieldName: "total_images",             isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "total_tokens",              name: "total_tokens",                 fieldName: "total_tokens",             isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 200,  isResizable: true, },
    { key: "word_sort_ratio_low",       name: "word_sort_ratio_low",          fieldName: "word_sort_ratio_low",      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "word_sort_ratio_high",      name: "word_sort_ratio_high",         fieldName: "word_sort_ratio_high",     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, },
    { key: "word_sort_ratio_std_err",   name: "word_sort_ratio_std_err (%)",  fieldName: "word_sort_ratio_std_err",  isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250,  isResizable: true, numberFormat: NumberFormat.Percentage,  maxDecimalPlaces: 2},
];

const TYPE_OPTIONS: IDropdownOption[] = [
    { key: "general", text: "general" },
    { key: "general_skip_low_wsr_0", text: "general_skip_low_wsr_0" },
    { key: "general_skip_low_wsr_1", text: "general_skip_low_wsr_1" },
    { key: "general_skip_low_wsr_2", text: "general_skip_low_wsr_2" },
    { key: "general_skip_low_wsr_3", text: "general_skip_low_wsr_3" },
    { key: "general_skip_low_wsr_4", text: "general_skip_low_wsr_4" },
];

interface LargeModelItem extends LargeModelBaseItem {
    succeeded_images: number;
    failed_images: number;
    failed_rate: number;
    total_images: number;
    p50_latency: number;
    p90_latency: number;
    p95_latency: number;
    p99_latency: number;
    word_sort_ratio: number;
    word_sort_ratio_low: number;
    word_sort_ratio_high: number;
    word_sort_ratio_std_err: number;
}

interface LargeModelDisplayItem extends LargeModelItem {
    category: string;
    datasetName: string;
}

interface LargeModelResult {
    [category: string]: {
        unordered_word_match_rate: {
            [matchRateType: string]: LargeModelItem;
        };
    };
}

export interface IProps extends ICommonProps {}

interface IState extends ICommonState<LargeModelResult> {
    dataItems: IDataItem<LargeModelResult>[];
    selectedMatchRateType: string;
    linkData?: any;
}

export class LargeModelOverview extends CommonView<
    IProps,
    IState,
    LargeModelResult
> {
    constructor(props: IProps) {
        super(props);
        const matchDatasetVersion =
            store.getState().settingReducer.matchDatasetVersion;

        this._onItemInvoked = this._onItemInvoked.bind(this);

        this.state = {
            dataItems: [],
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: matchDatasetVersion,
            selectedMatchRateType: TYPE_OPTIONS[0].key.toString(),
            viewType: store.getState().globalReducer.viewType,
        };

        store.dispatch(
            updateStateAction({
                columns: OVERVIEW_COLUMNS,
            })
        );
    }

    render(): React.ReactNode {
        const { selectedMatchRateType, viewType } = this.state;

        return (
            <>
                <Stack horizontal style={{ marginTop: "20px" }}>
                    <Label>Rate Type&nbsp;:&nbsp;</Label>
                    <Dropdown
                        selectedKey={selectedMatchRateType}
                        options={TYPE_OPTIONS}
                        styles={{
                            dropdown: {
                                width: 200,
                            },
                        }}
                        onChange={(
                            _: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption
                        ) => {
                            if (
                                option &&
                                selectedMatchRateType !== option.key
                            ) {
                                this.setState({
                                    selectedMatchRateType:
                                        option.key.toString(),
                                });
                            }
                        }}
                    ></Dropdown>
                </Stack>
                <FullScreen>
                    {viewType === "Table" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsTable()}
                        </div>
                    )}

                    {viewType === "Chart" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsChart()}
                        </div>
                    )}
                </FullScreen>
            </>
        );
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: OVERVIEW_COLUMNS,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.LargeModelMetrics
                    }_Overivew`
                }`,
            })
        );
    }

    queryMetricsResult(): void {
        this._queryMetricsResult("overall_similarity_scores.json");
    }

    exportAction = () => {
        exportGeneralMetrics(
            this.exportData,
            OVERVIEW_COLUMNS,
            "LargeModelOverview"
        );
    };

    private _renderAsChart() {
        const { matchDatasetVersion } = this.state;
        const data = this._prepareRenderData(matchDatasetVersion!);

        const chartElementArr = data.map(([datasetName, groupData], index) => {
            datasetName =
                datasetName.slice(0, datasetName.indexOf(":")) +
                "    Dataset: " +
                datasetName.slice(
                    datasetName.indexOf(":") + 1,
                    datasetName.length
                );
            const chartData = groupData.map(([category, transformedItems]) => {
                const series = transformedItems.map(
                    (transformedItem, tIndex) => {
                        const focusOnData = isNaN(
                            transformedItem.renderItem.word_sort_ratio
                        )
                            ? 0
                            : transformedItem.renderItem.word_sort_ratio;
                        return {
                            key: `series_${tIndex}`,
                            data: focusOnData,
                            color: getColorByIndex(tIndex),
                            legend:
                                transformedItem.data?.recordDetail.name ?? "",
                        };
                    }
                );
                return {
                    name: category,
                    series: series,
                };
            });

            return (
                <Fragment key={`chart_${datasetName}`}>
                    <DocumentCard
                        key={`chart_${datasetName}`}
                        className="overview__card"
                        type={DocumentCardType.compact}
                    >
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                className="overview__title"
                                title={datasetName}
                            />
                            <VerticalBarChart
                                id={"root" + index.toString()}
                                height={600}
                                data={chartData}
                                isDarkTheme={this.props.isDarkTheme}
                            ></VerticalBarChart>
                        </DocumentCardDetails>
                    </DocumentCard>
                </Fragment>
            );
        });

        return chartElementArr;
    }

    private _renderAsTable() {
        const { records } = this.props;
        const { selectedColumns, matchDatasetVersion, expandItem } = this.state;

        let columnList: any[] = OVERVIEW_COLUMNS;
        const columns: TableColumn[] = columnList.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const data = this._prepareRenderData(matchDatasetVersion!);
        this.exportData = data;

        const tableElementArr = data.map(([datasetName, groupData], index) => {
            datasetName =
                datasetName.slice(0, datasetName.indexOf(":")) +
                "    Dataset: " +
                datasetName.slice(
                    datasetName.indexOf(":") + 1,
                    datasetName.length
                );

            const tableDataDict = new Map<
                string,
                (LargeModelDisplayItem | null)[]
            >();

            groupData.forEach(([_, transformedItems]) => {
                const renderItems = transformedItems.map(
                    (transformedItem) => transformedItem.renderItem
                );

                tableDataDict.set(_, renderItems);
            });

            return (
                <div className="overview__detail" key={`table_${datasetName}`}>
                    {this.props.alwaysShow ? (
                        <TableList
                            evalDataCount={records.length}
                            evalData={tableDataDict}
                            columns={columns}
                            downloadTableTitle={datasetName}
                            isDarkTheme={this.props.isDarkTheme}
                            tableTitle={""}
                            onItemInvoked={this._onItemInvoked}
                            hideHeader
                        />
                    ) : (
                        <ExpandCard
                            text={datasetName}
                            expandAll={
                                expandItem?.get(index) ??
                                store.getState().globalReducer.expandAll
                            }
                            onExpandChange={(expand: boolean) => {
                                expandItem?.set(index, expand);
                                this.setState({ expandItem: expandItem }, () =>
                                    this._expandCountChange(data.length)
                                );
                            }}
                            isDarkTheme={this.props.isDarkTheme}
                        >
                            <TableList
                                evalDataCount={records.length}
                                evalData={tableDataDict}
                                columns={columns}
                                downloadTableTitle={datasetName}
                                isDarkTheme={this.props.isDarkTheme}
                                tableTitle={""}
                                onItemInvoked={this._onItemInvoked}
                                hideHeader
                            />
                        </ExpandCard>
                    )}
                </div>
            );
        });

        return tableElementArr;
    }

    private _prepareRenderData(matchDatasetVersion: boolean) {
        const { selectedMatchRateType } = this.state;

        const allDatasetNames = this._getLanguageList(matchDatasetVersion);
        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const categories = Array.from(
                new Set(
                    items.flatMap((item) =>
                        item?.metrics ? Object.keys(item.metrics) : []
                    )
                )
            );

            const groupData = categories.map((category) => {
                const transformedItems = items.map((item) => {
                    let largeModelDisplayItem: LargeModelDisplayItem = {
                        datasetName: datasetName,
                        category: category,
                        completion_tokens: NaN,
                        prompt_tokens: NaN,
                        total_tokens: NaN,
                        inserts: NaN,
                        deletes: NaN,
                        subs: NaN,
                        weight: NaN,
                        succeeded_images: NaN,
                        failed_images: NaN,
                        failed_rate: NaN,
                        total_images: NaN,
                        p50_latency: NaN,
                        p90_latency: NaN,
                        p95_latency: NaN,
                        p99_latency: NaN,
                        word_sort_ratio: NaN,
                        word_sort_ratio_low: NaN,
                        word_sort_ratio_high: NaN,
                        word_sort_ratio_std_err: NaN,
                    };

                    if (item && item.metrics && item.metrics[category]) {
                        const largeModelItem =
                            item.metrics[category].unordered_word_match_rate[
                                selectedMatchRateType
                            ];

                        if (largeModelItem) {
                            largeModelDisplayItem = {
                                datasetName: datasetName,
                                category: category,
                                ...largeModelItem,
                            };
                        }
                    }

                    return {
                        data: item,
                        renderItem: largeModelDisplayItem,
                    } as TransformedDataItem<
                        IDataItem<LargeModelResult>,
                        LargeModelDisplayItem
                    >;
                });

                return [category, transformedItems] as [
                    string,
                    TransformedDataItem<
                        IDataItem<LargeModelResult>,
                        LargeModelDisplayItem
                    >[]
                ];
            });

            return [datasetName, groupData] as [
                string,
                [
                    string,
                    TransformedDataItem<
                        IDataItem<LargeModelResult>,
                        LargeModelDisplayItem
                    >[]
                ][]
            ];
        });

        return data;
    }

    private deepLinkHandler(key: string, linkData: any): void {
        if (this.props.setSelectedSubPivot) {
            this.props.setSelectedSubPivot(key, linkData);
        }
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const values = item as any[];
        if (values?.length >= 2) {
            const displayItemsArr = values[1] as LargeModelDisplayItem[];
            if (displayItemsArr?.length > 0) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = displayItemsArr[0].datasetName;
                linkData.toSelectCategory = displayItemsArr[0].category;

                this.deepLinkHandler &&
                    this.deepLinkHandler("ByImage", linkData);
            }
        }
    }
}
