import React from "react";
import { Modal } from "@fluentui/react";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { IMAGECOLUMNS } from "./EntityConstants";
import { TextlineCharMetric } from "./TextlineView";
import {
    ImageVisualizer,
    OcrPolygon,
    TableColumn,
    TableList,
} from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    IMergedMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import _ from "lodash";
import "../Common/MetricStyle.scss";
import { METRICS_LVL_OPTIONS, OcrEntityStateInSession } from "./EntityMetrics";
import { exportTableListData } from "../../Utils/ExportFile";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

interface ImageCharMetric extends IMetrics<IMetricUnit> {
    wordCount: number;
    sentenceCount: number;
    correct: number;
    substitueRate: number;
    substitueCount: number;
    deleteRate: number;
    deleteCount: number;
    insertRate: number;
    insertCount: number;
    error: number;
    sentenceErrorRate: number;
    imageUrl: string;
    category: string;
    entityErrorCount: number;
    wordErrorCount: number;
}

type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<TextlineCharMetric[]>;

interface IState extends IMetricState<ImageCharMetric> {
    entityList: string[];
    selectedCategories?: string[];
    selectEntity?: string;
    selectImageId?: string;
    selectLanguage?: string;
    clickNumber?: number;
    imageVisItems?: any[];
}

interface IProps extends IMetricProps {
    availableLanguages: string[];
    linkData?: {
        toSelectLanguage: string;
        toSelectEntity: string;
    };
    onDismiss?: (imageId: any) => void;
}

export class EntityImageVIew extends MetricsView<
    IProps,
    IState,
    ImageCharMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        let selectLanguage = props.linkData?.toSelectLanguage;
        const stateKey = this.getSessionStateKey(
            props.records,
            Workspaces.Ocr,
            Typename.EntityMetrics
        );

        if (selectLanguage) {
            const stateStr = JSON.stringify({
                selectLanguage: selectLanguage,
            });
            sessionStorage.setItem(stateKey, stateStr);
        } else {
            const stateStr = sessionStorage.getItem(stateKey);
            if (stateStr) {
                const sessionState = JSON.parse(
                    stateStr
                ) as OcrEntityStateInSession;
                if (sessionState.selectLanguage) {
                    selectLanguage = sessionState.selectLanguage;
                }
            }
        }

        this.state = {
            evalData: {},
            entityList: [],
            selectLanguage: selectLanguage,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
            selectEntity: this.props.linkData
                ? this.props.linkData.toSelectEntity
                : undefined,
            level: "lv2",
        };
    }

    public render() {
        const { records } = this.props;
        const {
            entityList,
            evalData,
            selectedCategories,
            selectEntity,
            selectImageId,
            imageVisItems,
            selectedColumns,
        } = this.state;
        const experiments = records.map((record) => record.name);
        const columns: TableColumn[] = _.cloneDeep(IMAGECOLUMNS)
            .filter(
                (value) =>
                    selectedColumns?.findIndex((col) => col === value.key) !==
                    -1
            )
            .map((col) => {
                if (col.fieldName === "Category") {
                    col.filterable = true;
                }

                return col;
            });

        let entity = entityList.includes(selectEntity ?? "")
            ? selectEntity!
            : entityList[0];

        let entityDict: any = {};
        let filecategory: any = {};

        let clickNumber: number = 0;

        if (evalData[entity]) {
            const keys = new Set(
                evalData[entity]
                    .filter((metric) => !!metric)
                    .flatMap((metric: any) => Object.keys(metric))
            );

            [...keys].forEach((key) => {
                const symbolicItem = evalData[entity].filter(
                    (metric) => !!metric
                )[0]![key];
                if (symbolicItem && "Category" in symbolicItem) {
                    filecategory[key] = evalData[entity].filter(
                        (metric) => !!metric
                    )[0]![key]["Category" as keyof IMetricUnit];
                }
            });
            [...keys]
                .filter((key) => {
                    return selectedCategories
                        ? selectedCategories.includes(filecategory[key])
                        : true;
                })
                .forEach((key) => {
                    entityDict[key] = evalData[entity]
                        .filter((metric) => !!metric)
                        .map((metric: any) => {
                            return key in metric ? metric[key] : null;
                        });
                });
        }
        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );
        this.exportData = entityDict;
        return (
            <>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    isOpen={!!selectImageId}
                    containerClassName="modal"
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                >
                    <ImageVisualizer
                        entity={entity}
                        experiments={experiments}
                        fileId={selectImageId}
                        evalList={imageItemsForVis}
                        onLoadVisualizer={(imageId: any) => {
                            let imageUrl: string = "";
                            if (imageId && evalData[entity]) {
                                evalData[entity].forEach((content: any) => {
                                    imageUrl = content[imageId]?.imageUrl;
                                });
                                return imageUrl;
                            } else {
                                return "";
                            }
                        }}
                        onRequestPolygons={(imageId?: string) =>
                            this._requestTextlinePolygons(imageId!, entity)
                        }
                        onRequestMetrics={(imageId?: string) =>
                            this._requestTextlineMetrics(imageId!, entity)
                        }
                        setImageMark={this.setImageMark}
                        onDismiss={(imageId: string) => this.onDismiss(imageId)}
                        clickNumber={this.state.clickNumber}
                    />
                </Modal>
                <FullScreen>
                    <TableList<ImageCharMetric>
                        key={this.state.selectLanguage}
                        evalDataCount={this.props.records.length}
                        evalData={entityDict}
                        columns={columns}
                        downloadTableTitle={this.state.selectLanguage}
                        isFullFilterMenu={true}
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [imageId] = item;
                            let invokedCategory: string = "";
                            if (imageId && evalData[entity]) {
                                if (imageId && evalData[entity]) {
                                    evalData[entity].forEach((content: any) => {
                                        if (!!content[imageId].Category) {
                                            invokedCategory =
                                                content[imageId].Category;
                                        }
                                    });
                                }
                            }
                            this.setState({
                                selectImageId: imageId,
                                selectCategory: invokedCategory,
                                clickNumber: clickNumber,
                            });
                        }}
                        renderTableHeader={this._renderTableHeader}
                        getDisplayEvalData={(displayItems: any[]) => {
                            this.getDisplayEvalData(displayItems);
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                        showDiffTypeToggle={true}
                    />
                </FullScreen>
            </>
        );
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<ImageCharMetric>> {
        return Promise.all([
            recordDetail.fetchMetrics<IMetrics<ImageCharMetric>>(metricName),
            recordDetail.dataset.fetchImageList(),
        ]).then(([records, imageList]: any) => {
            Object.entries(records).forEach(([_, val]: any) => {
                Object.entries(val).forEach(
                    ([imageId, content]: [string, any]) => {
                        const imageName =
                            imageList.find((image: string) =>
                                image
                                    .toLowerCase()
                                    .includes(imageId.toLowerCase())
                            ) ?? `${imageId}.jpg`;
                        content.imageUrl = recordDetail.dataset.getImageUrl(
                            `${content.Category}/${imageName}`
                        );
                        content.Correct = Math.round(
                            val[imageId].Correct * 100
                        );
                        content.InsertCount = Math.round(
                            val[imageId].InsertRate * val[imageId].WordCount
                        );
                        content.SubstitueCount = Math.round(
                            val[imageId].SubstitueRate * val[imageId].WordCount
                        );
                        content.DeleteCount = Math.round(
                            val[imageId].DeleteRate * val[imageId].WordCount
                        );
                        content.Error = Math.round(val[imageId].Error * 100);
                        content.SentenceErrorRate = Math.round(
                            val[imageId].SentenceErrorRate * 100
                        );
                        content.WordErrorCount = Math.round(
                            (val[imageId].WordCount * val[imageId].Error) / 100
                        );
                        content.EntityErrorCount = Math.round(
                            (val[imageId].SentenceCount *
                                val[imageId].SentenceErrorRate) /
                                100
                        );
                    }
                );
            });
            return records;
        });
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                levels: METRICS_LVL_OPTIONS,
                selectedLevel: "lv2",
                columns: IMAGECOLUMNS,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.EntityMetrics
                    }`
                }_image`,
            })
        );
    }
    exportAction = () => {
        exportTableListData(
            this.exportData,
            IMAGECOLUMNS,
            "EntityByImageMetrics"
        );
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);
        if (this.state.level !== prevState.level) {
            this.onEvaluationRecordChanged();
        }
        if (this.state.matchDatasetVersion !== prevState.matchDatasetVersion) {
            this.setState({ selectLanguage: undefined }, () => {
                this.onEvaluationRecordChanged();
            });
        }
    }

    getLanguageList(matchDatasetVersion: boolean): string[] {
        const { availableLanguages } = this.props;

        return availableLanguages.length > 0
            ? availableLanguages
            : super.getLanguageList(matchDatasetVersion);
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);
        this._queryEntityDetails(selectLanguage ?? languageList[0]);
    }

    private _onQueryButtonClicked = (selectLanguage?: string) => {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;
        if (records.length > 0 && selectLanguage) {
            const details = this.filterRecordDetails(
                selectLanguage,
                matchDatasetVersion!
            );
            const lvl_str =
                this.state.level === "lv2" ? "" : `_${this.state.level}`;
            this.showEvaluationResult(
                details,
                `overall_entity_image_metrics${lvl_str}.json`
            );
        }
        this.setState({
            selectLanguage: selectLanguage,
        });
    };

    private _renderTableHeader(): JSX.Element {
        const {
            entityList,
            selectLanguage,
            selectEntity,
            matchDatasetVersion,
        } = this.state;

        let languages = this.getLanguageList(matchDatasetVersion!);

        let imageConfigurations: ITableConfigurations = [
            {
                key: "dataset",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!({
                        selectLanguage: language!.text,
                    });
                },
            },
            {
                key: "entities",
                text: "Entities:",
                options: entityList,
                selectedKey: entityList.includes(selectEntity ?? "")
                    ? selectEntity!
                    : entityList[0],
                onChange: (entity) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!({
                        selectEntity: entity!.text,
                    });
                },
            },
        ];

        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _queryEntityDetails(selectedLanguage?: string) {
        this._onQueryButtonClicked(selectedLanguage);
    }

    private _onOptionsChanged(newOptions: {
        [key: string]: string | undefined;
    }) {
        let selectLanguage: any;
        let selectEntity: any;
        selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;

        selectEntity =
            "selectEntity" in newOptions
                ? newOptions["selectEntity"]
                : this.state.selectEntity;

        this.setState(
            {
                selectLanguage: selectLanguage,
                selectEntity: selectEntity,
            },
            () => {
                const { records, linkData, deepLinkHandler } = this.props;
                const stateKey = this.getSessionStateKey(
                    records,
                    Workspaces.Ocr,
                    Typename.EntityMetrics
                );
                const stateInSession: OcrEntityStateInSession = {
                    selectLanguage: selectLanguage,
                };

                const stateStr = JSON.stringify(stateInSession);
                sessionStorage.setItem(stateKey, stateStr);

                if (deepLinkHandler && linkData?.toSelectLanguage) {
                    deepLinkHandler("image", {
                        toSelectLanguage: undefined,
                    });
                }

                this._onQueryButtonClicked(selectLanguage);
            }
        );
    }

    private _requestTextlinePolygons(
        imageId: string,
        entity: string
    ): Promise<OcrPolygons[]> {
        const { selectLanguage, evalData, selectImageId, matchDatasetVersion } =
            this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );
        const category = evalData[entity].map((content: any) => {
            return content[imageId ?? selectImageId].Category;
        });

        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_alignment_polygons.json"
                        : "alignment_polygons.json";
                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${Array.from(new Set(category))}/${filename}`
                );
            })
        );
    }

    private _requestTextlineMetrics(
        imageId: string,
        entity: string
    ): Promise<OcrMetrics[]> {
        const { selectLanguage, evalData, matchDatasetVersion } = this.state; //selectImageId
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );
        const category = evalData[entity].map((content: any) => {
            return content[imageId].Category;
        });
        const lvl_str =
            this.state.level === "lv2" ? "" : `_${this.state.level}`;
        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? imageId!.charAt(imageId!.length - 1) +
                          "_textline_word_metrics.json"
                        : "textline_word_metrics.json";
                return Promise.all([
                    detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                        `${Array.from(
                            new Set(category)
                        )}/textline_entity_metrics${lvl_str}.json`
                    ),
                    detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                        `${Array.from(new Set(category))}/${filename}`
                    ),
                ]).then((results) => {
                    let entity_metrics: OcrMetrics = results[0];
                    const general_metrics: OcrMetrics = results[1];
                    for (const key in entity_metrics) {
                        entity_metrics[key] = entity_metrics[key].concat(
                            general_metrics[key]
                        );
                    }
                    return entity_metrics;
                });
            })
        );
    }

    protected getEntityList(
        evalData: IMergedMetrics<ImageCharMetric>
    ): string[] {
        return Object.keys(evalData).sort();
    }

    private onDismiss(imageId: any) {
        this.setState({
            selectImageId: imageId,
        });
    }
    private getDisplayEvalData(displayItems: any[]) {
        this.setState({
            imageVisItems: displayItems,
        });
    }
}
