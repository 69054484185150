import * as React from "react";
import "./OverviewTable.scss";
import "./TableList.scss";

import {
    // DetailsList
    IColumn,
} from "@fluentui/react";

import _ from "lodash";
import { GroupListItem } from "./GroupListItem";

export interface ChildArray<T> extends Array<T> {
    childrens?: T[][];
}

export interface IGroupResult {
    childrenStat?: string;
    childrenStatKey?: string;
    [key: string]: any;
}

interface IProps {
    columns: IColumn[];
    diffAsPercentage: boolean;
    evalData?: [string, ChildArray<IGroupResult>[]][];
    highlightKey?: string[];
    style?: React.CSSProperties;
    isDarkTheme?: boolean;
    hiddenTitle?: boolean;
    targetKeys?: string[];
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
    onCellDoubleClick?: (item: any, column: IColumn, ev?: Event) => void;
}

interface IState {
    columns: IColumn[];
    childExpandStat: Map<string, boolean>;
    evalData?: [string, ChildArray<IGroupResult>[]][];
}

export class GroupList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            evalData: this.props.evalData,
            columns: this.props.columns,
            childExpandStat: new Map<string, boolean>(),
        };
    }

    public render() {
        return <>{this._renderList()}</>;
    }

    private _renderList = () => {
        const { evalData, columns } = this.state;
        const {
            highlightKey,
            style = { marginTop: "20px" },
            hiddenTitle = false,
            targetKeys,
            diffAsPercentage,
            onCellDoubleClick,
        } = this.props;
        return (
            <React.Fragment key={this.createKey()}>
                {evalData?.map((item) => {
                    return (
                        <GroupListItem
                            style={style}
                            hiddenTitle={hiddenTitle}
                            highlightKey={highlightKey}
                            columns={columns}
                            evalData={item}
                            targetKeys={targetKeys}
                            onItemInvoked={this._onItemInvoked}
                            onCellDoubleClick={onCellDoubleClick}
                            isDarkTheme={this.props.isDarkTheme}
                            diffAsPercentage={diffAsPercentage}
                        />
                    );
                })}
            </React.Fragment>
        );
    };

    private _onItemInvoked = (item?: any[]) => {
        if (this.props.onItemInvoked) {
            this.props.onItemInvoked(item);
        }
    };

    componentWillReceiveProps(nextProps: IProps, nextContext: any) {
        if (!_.isEqual(nextProps, this.props)) {
            this.setState({
                evalData: nextProps.evalData,
                columns: nextProps.columns,
            });
        }
    }

    shouldComponentUpdate(
        nextProps: IProps,
        nextState: IState,
        nextContext: any
    ): boolean {
        if (
            _.isEqual(nextProps, this.props) &&
            _.isEqual(nextState, this.state)
        ) {
            return false;
        }
        return true;
    }
    private createKey = () => {
        return Array.from(this.state.childExpandStat.values()).join("");
    };
}
