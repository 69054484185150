import React from "react";
import { Modal } from "@fluentui/react";
import { IMetricProps, IMetricState, MetricsView } from "../Common/MetricView";
import { TextlineCharMetric } from "./TextlineView";
import {
    ColumnValueType,
    TableColumn,
    TableList,
    ImageVisualizer,
    OcrPolygon,
} from "../../Controls";
import {
    IMetricUnit,
    IMetrics,
    RecordDetail,
    Typename,
    Workspaces,
} from "../../DataContract";
import { ITableConfigurations, TableHeader } from "../../Controls/TableHeader";
import { OcrGeneralStateInSession } from "./GeneralMetrics";
import { resetScrollBarOfScrollablePane } from "../../Utils";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

interface ImageCharMetric extends IMetricUnit {
    wordCount: number;
    sentenceCount: number;
    correct: number;
    substitueRate: number;
    deleteRate: number;
    insertRate: number;
    substitueCount: number;
    deleteCount: number;
    insertCount: number;
    error: number;
    sentenceErrorRate: number;
    totalRunTimeInSeconds: number;
    errorCount: number;
    imageUrl: string;
}

type OcrPolygons = IMetrics<OcrPolygon[]>;
type OcrMetrics = IMetrics<TextlineCharMetric[]>;

interface IState extends IMetricState<ImageCharMetric> {
    selectLanguage?: string;
    selectCategory?: string;
    selectImageId?: string;
    categoryList: string[];
    clickNumber?: number;
    imageVisItems?: any[];
}

interface IProps extends IMetricProps {
    linkData?: {
        toSelectLanguage: string;
        toSelectCategory: string;
    };
    onDismiss?: (imageId: any) => void;
}

// prettier-ignore
export const imageColumns: TableColumn[] = [
    { key: "imageId",            name: "ImageId",                isKey: true,   valueType: ColumnValueType.String,  minWidth: 100,  maxWidth: 150, },
    { key: "wordCount",          name: "WordCount",              isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 150, },
    { key: "insertCount",        name: "WordInsertCount",        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250, filterName: "Show insert error (>0) lines", },
    { key: "substitueCount",     name: "WordSubstitueCount",     isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250, filterName: "Show subs error (>0) lines", },
    { key: "deleteCount",        name: "WordDeleteCount",        isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250, filterName: "Show delete error (>0) lines", },
    { key: "error",              name: "WordErrorRate (%)",      isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250, supportSwitchGapUnit: true, maxDecimalPlaces:2},
    { key: "errorCount",         name: "WordErrorCount",         isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 150, },
    { key: "sentenceErrorRate",  name: "SentenceErrorRate (%)",  isKey: false,  valueType: ColumnValueType.Number,  minWidth: 150,  maxWidth: 250, supportSwitchGapUnit: true, maxDecimalPlaces:2},
    { key: "sentenceCount",      name: "SentenceCount",          isKey: false,  valueType: ColumnValueType.Number,  minWidth: 100,  maxWidth: 150, },
];

export class ImageMetricView extends MetricsView<
    IProps,
    IState,
    ImageCharMetric
> {
    constructor(props: IProps) {
        super(props);

        this._renderTableHeader = this._renderTableHeader.bind(this);
        this._onOptionsChanged = this._onOptionsChanged.bind(this);

        let selectLanguage = props.linkData?.toSelectLanguage;
        let selectCategory = props.linkData?.toSelectCategory;

        if (selectLanguage === undefined || selectCategory === undefined) {
            const stateKey = this.getSessionStateKey(
                props.records,
                Workspaces.Ocr,
                Typename.GeneralMetrics
            );
            const stateStr = sessionStorage.getItem(stateKey);
            if (stateStr) {
                const sessionState = JSON.parse(
                    stateStr
                ) as OcrGeneralStateInSession;

                if (sessionState.selectLanguage) {
                    selectLanguage = sessionState.selectLanguage;
                }

                if (sessionState.selectCategory) {
                    selectCategory = sessionState.selectCategory;
                }
            }
        }

        this.state = {
            evalData: {},
            categoryList: [],
            selectLanguage: selectLanguage,
            selectCategory: selectCategory,
            matchDatasetVersion:
                store.getState().settingReducer.matchDatasetVersion,
        };
        store.dispatch(
            updateStateAction({
                columns: imageColumns,
            })
        );
    }

    public render() {
        const { records } = this.props;
        const { evalData, selectImageId, imageVisItems, selectedColumns } =
            this.state;
        const experiments = records.map((record) => record.name);

        let clickNumber: number = 0;
        let columns: TableColumn[] = imageColumns.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );
        const imageItemsForVis = imageVisItems?.map(
            (imageVisItem) => imageVisItem[1]
        );
        const tableKey = `${this.state.selectLanguage}_${this.state.selectCategory}`;
        return (
            <>
                <Modal
                    styles={{
                        main: {
                            width: "100%!important",
                            height: "100%!important",
                        },
                    }}
                    isOpen={!!selectImageId}
                    containerClassName="modal"
                    onDismiss={() =>
                        this.setState({ selectImageId: undefined })
                    }
                >
                    <ImageVisualizer
                        experiments={experiments}
                        fileId={selectImageId}
                        evalList={imageItemsForVis}
                        onLoadVisualizer={(imageId) => {
                            if (imageId && evalData[imageId]) {
                                return evalData[imageId][0]!.imageUrl;
                            } else {
                                return "";
                            }
                        }}
                        onRequestPolygons={(imageId?: string) =>
                            this._requestTextlinePolygons(imageId!)
                        }
                        onRequestMetrics={(imageId?: string) =>
                            this._requestTextlineMetrics(imageId!)
                        }
                        setImageMark={this.setImageMark}
                        onDismiss={(imageId: string) => this.onDismiss(imageId)}
                        clickNumber={this.state.clickNumber}
                    />
                </Modal>
                <FullScreen>
                    <TableList<ImageCharMetric>
                        key={tableKey}
                        evalDataCount={this.props.records.length}
                        evalData={evalData}
                        columns={columns}
                        downloadTableTitle={tableKey}
                        isFullFilterMenu={true}
                        onItemInvoked={(item: any, index: any) => {
                            clickNumber = index;
                            const [imageId] = item;
                            this.setState({
                                selectImageId: imageId,
                                clickNumber: clickNumber,
                            });
                        }}
                        renderTableHeader={this._renderTableHeader}
                        getDisplayEvalData={(displayItems: any[]) => {
                            this.getDisplayEvalData(displayItems);
                        }}
                        isDarkTheme={this.props.isDarkTheme}
                        showDiffTypeToggle={true}
                    />
                </FullScreen>
            </>
        );
    }

    componentWillMount(): void {
        this.loadStateFromReduxStore();
        super.componentWillMount();
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: imageColumns,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.GeneralMetrics
                    }`
                }_ByImage`,
            })
        );
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        super.componentDidUpdate(prevProps, prevState);

        if (this.state.matchDatasetVersion !== prevState.matchDatasetVersion) {
            this.setState({ selectLanguage: undefined }, () => {
                this.onEvaluationRecordChanged();
            });
        }
    }

    async queryEvaluationResult(
        recordDetail: RecordDetail,
        metricName: string
    ): Promise<IMetrics<ImageCharMetric>> {
        const { selectCategory: category } = this.state;
        return Promise.all([
            recordDetail.fetchMetricsWithCamelCasing<IMetrics<ImageCharMetric>>(
                metricName
            ),
            recordDetail.dataset.fetchImageListByCategory(category!),
        ]).then(([records, imageList]) => {
            Object.entries(records).forEach(([key, val]) => {
                records[key].errorCount = Math.round(
                    (val.wordCount * val.error) / 100
                );
                records[key].substitueCount = Math.round(
                    (val.wordCount * val.substitueRate) / 100
                );
                records[key].deleteCount = Math.round(
                    (val.wordCount * val.deleteRate) / 100
                );
                records[key].insertCount = Math.round(
                    (val.wordCount * val.insertRate) / 100
                );
                const imageName =
                    imageList.find((image) =>
                        image.toLowerCase().includes(key.toLowerCase())
                    ) ?? `${key}.jpg`;
                records[key].imageUrl = recordDetail.dataset.getImageUrl(
                    `${category}/${imageName}`
                );
            });
            return records;
        });
    }

    onEvaluationRecordChanged() {
        this._onEvaluationRecordChanged(this.state.matchDatasetVersion!);
    }

    _onEvaluationRecordChanged(matchDatasetVersion: boolean) {
        const { selectLanguage } = this.state;
        const languageList = this.getLanguageList(matchDatasetVersion);

        if (languageList.length > 0) {
            const language = selectLanguage ?? languageList[0];
            this._onOptionsChanged(
                {
                    selectLanguage: language,
                },
                matchDatasetVersion
            );
        } else {
            this.setState({
                evalData: {},
            });
        }
    }

    private _requestTextlinePolygons(imageId?: string): Promise<OcrPolygons[]> {
        const {
            selectLanguage,
            selectCategory,
            selectImageId,
            matchDatasetVersion,
        } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_alignment_polygons.json"
                        : "alignment_polygons.json";
                return detail.fetchMetricsWithCamelCasing<OcrPolygons>(
                    `${selectCategory}/${filename}`
                );
            })
        );
    }

    private _requestTextlineMetrics(imageId?: string): Promise<OcrMetrics[]> {
        const {
            selectLanguage,
            selectCategory,
            selectImageId,
            matchDatasetVersion,
        } = this.state;
        const details = this.filterRecordDetails(
            selectLanguage!,
            matchDatasetVersion!
        );

        return Promise.all(
            details.map((detail) => {
                const storageVersion =
                    detail.getRawProp<string>("storageVersion");
                const filename =
                    storageVersion === "v2"
                        ? (imageId ?? selectImageId!).charAt(
                              (imageId ?? selectImageId!).length - 1
                          ) + "_textline_word_metrics.json"
                        : "textline_word_metrics.json";
                return detail.fetchMetricsWithCamelCasing<OcrMetrics>(
                    `${selectCategory}/${filename}`
                );
            })
        );
    }

    private _onQueryButtonClicked = () => {
        const { records } = this.props;
        const { selectLanguage, selectCategory, matchDatasetVersion } =
            this.state;
        if (records.length > 0) {
            const details = this.filterRecordDetails(
                selectLanguage!,
                matchDatasetVersion!
            );
            this.showEvaluationResult(
                details,
                `${selectCategory!}/image_word_metrics.json`
            );
        }
    };

    private _renderTableHeader(): JSX.Element {
        const {
            categoryList,
            selectLanguage,
            selectCategory,
            matchDatasetVersion,
        } = this.state;
        let languages = this.getLanguageList(matchDatasetVersion!);
        let imageConfigurations: ITableConfigurations = [
            {
                key: "languages",
                text: "Dataset:",
                options: languages,
                selectedKey: selectLanguage ?? languages[0],
                onChange: (language) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!(
                        {
                            selectLanguage: language!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
            {
                key: "categories",
                text: "Category:",
                options: categoryList,
                selectedKey: selectCategory ?? categoryList[0],
                onChange: (catagory) => {
                    resetScrollBarOfScrollablePane();
                    this._onOptionsChanged!(
                        {
                            selectCategory: catagory!.text,
                        },
                        matchDatasetVersion!
                    );
                },
            },
        ];
        return (
            <TableHeader
                options={imageConfigurations}
                onQueryButtonClick={this._onQueryButtonClicked}
            />
        );
    }

    private _onOptionsChanged(
        newOptions: { [key: string]: string | undefined },
        matchDatasetVersion: boolean
    ) {
        let selectLanguage: any;
        let selectCategory: any;
        selectLanguage =
            "selectLanguage" in newOptions
                ? newOptions["selectLanguage"]
                : this.state.selectLanguage;
        selectCategory =
            "selectCategory" in newOptions
                ? newOptions["selectCategory"]
                : this.state.selectCategory;

        let categories = this.getCategoryList(
            selectLanguage!,
            matchDatasetVersion
        );
        selectCategory =
            selectCategory && categories.indexOf(selectCategory) >= 0
                ? selectCategory
                : categories[0];

        this.setState(
            {
                selectCategory: selectCategory,
                selectLanguage: selectLanguage,
                categoryList: categories,
            },
            () => {
                const { records } = this.props;
                const stateKey = this.getSessionStateKey(
                    records,
                    Workspaces.Ocr,
                    Typename.GeneralMetrics
                );
                const stateInSession: OcrGeneralStateInSession = {
                    selectLanguage: selectLanguage,
                    selectCategory: selectCategory,
                };

                const stateStr = JSON.stringify(stateInSession);
                sessionStorage.setItem(stateKey, stateStr);

                this._onQueryButtonClicked();
            }
        );
    }
    private onDismiss(imageId: any) {
        this.setState({
            selectImageId: imageId,
        });
    }
    private getDisplayEvalData(displayItems: any[]) {
        this.setState({
            imageVisItems: displayItems,
        });
    }
}
