import React from "react";
import "../Common/MetricStyle.scss";
import {
    Label,
    Stack,
    Toggle,

    //DetailsList
    DetailsList,
    SelectionMode,
} from "@fluentui/react";

import { DatasetSet, IDataItem, Typename } from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";

import {
    OverviewTable,
    TableColumn,
    ColumnValueType,
    NumContrastPolicy,
} from "../../Controls";

import { formatPercentDisplay, optimizeDecimalPlaces } from "../../Utils";
import { FieldAnalysisList, StatsCol } from "../../Controls/FieldAnalysisList";
import {
    AnalysisType,
    ANALYSIS_COUNT_COLUMNS,
    ExtractStatisticListByField,
    IWerDataItem,
    MetricsAnalysis,
    QueryCategoryAnalysisCount,
    RankCategoriesAnalysis,
    StatisticalEntity,
} from "./OcrDataContract";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { exportGeneralMetrics } from "../../Utils/ExportFile";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";
import { NoDataTip } from "../../Controls/NoDataTip";

interface OcrDetectorResult {
    category: string;
    gtCount: number;
    fnCount: number;
    fnr: number;
    detectedCount: number;
    fdCount: number;
    fdr: number;
    datasetName: string;
}

interface OcrDetectionMetrics {
    detector_metrics: Array<OcrDetectorResult>;
}

const ANALYSIS_FIELDS = ["fdr", "fnr"];
const AGGREGATED_CATEGORIES = ["Overall", "Overall Documents"];
const SORTED_FIELD: string = StatsCol.DiffPropName(0);

const ANALYSIS_SORTED_COLUMNS_ARR = ANALYSIS_FIELDS.map((field) => {
    // prettier-ignore
    return [
        { key: "name",                      name: "Category",          fieldName: "name",                      minWidth: 120,  maxWidth: 160, isResizable: true, valueType:ColumnValueType.String },
        { key: "language",                  name: "Language",          fieldName: "language",                  minWidth: 180,  maxWidth: 300, isResizable: true, valueType:ColumnValueType.String },
        { key: StatsCol.ValuesPropName(0),  name: field.toUpperCase(), fieldName: StatsCol.ValuesPropName(0),  minWidth: 180,  maxWidth: 200, isResizable: true, valueType:ColumnValueType.Tuple, contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen },
    ]
});

// prettier-ignore
const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",          name: "Category",           fieldName: "category",              valueType: ColumnValueType.String,  minWidth: 100,   maxWidth: 200, isResizable: true, distinctStr: true },
    { key: "gtCount",           name: "GT Count",           fieldName: "gtCount",               valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "detectedCount",     name: "Detected Count",     fieldName: "detectedCount",         valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
    { key: "fnCount",           name: "FN Count",           fieldName: "fnCount",               valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "fnr",               name: "FNR(%)",             fieldName: "fnr",                   valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
    { key: "fdCount",           name: "FD Count",           fieldName: "fdCount",               valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
    { key: "fdr",               name: "FDR(%)",             fieldName: "fdr",                   valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
];

interface IProps extends ICommonProps {
    analysisOn?: boolean;
    updateAnalysisState?: (
        metricsAnalysis: MetricsAnalysis,
        isOn: boolean
    ) => void;
}

interface IState extends ICommonState<OcrDetectionMetrics> {
    dataItems: IDataItem<OcrDetectionMetrics>[];
    diffAsPercentage: boolean;
    linkData?: any;
}

export class DetectionMetricsOverview extends CommonView<
    IProps,
    IState,
    OcrDetectionMetrics
> {
    constructor(props: IProps) {
        super(props);
        this._onAnalysisItemInvoked = this._onAnalysisItemInvoked.bind(this);
        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);
        this.deepLinkHandler = this.deepLinkHandler.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.state = {
            dataItems: [],
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: true,
            diffAsPercentage: false,
        };
    }

    public render() {
        return (
            <FullScreen>
                <div style={{ overflow: "hidden auto", height: "100%" }}>
                    {this._renderAsTable()}
                </div>
            </FullScreen>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("overall_detector_metrics.json");
    }

    private _renderAsTable() {
        const { records } = this.props;

        const { selectedColumns, matchDatasetVersion, expandItem } = this.state;

        const columns: TableColumn[] = OVERVIEW_COLUMNS.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const data = this._prepareRenderData(matchDatasetVersion!);
        this.exportData = data;
        const result: JSX.Element[] = [];
        if (records.length === 2) {
            const analysisElements = this._renderAnalysisArea(data);
            if (analysisElements) {
                result.push(analysisElements);
            }
        }

        const dataElement =
            this.state.dataItems.length !== 0 && data.length !== 0 ? (
                <>
                    {data.map(([datasetName, groupData], index) => {
                        const shownDataset = datasetName;
                        datasetName =
                            datasetName.slice(0, datasetName.indexOf(":")) +
                            "    Dataset: " +
                            datasetName.slice(
                                datasetName.indexOf(":") + 1,
                                datasetName.length
                            );
                        const tableData = groupData.map(([_, werItems]) => {
                            return werItems.map((werItem) => werItem.wer);
                        });
                        if (tableData) {
                            return (
                                <div
                                    className="overview__detail"
                                    key={`table_${datasetName}`}
                                >
                                    <ExpandCard
                                        text={datasetName}
                                        expandAll={
                                            expandItem?.get(index) ??
                                            store.getState().globalReducer
                                                .expandAll
                                        }
                                        onExpandChange={(expand: boolean) => {
                                            expandItem?.set(index, expand);
                                            this.setState(
                                                { expandItem: expandItem },
                                                () =>
                                                    this._expandCountChange(
                                                        data.length
                                                    )
                                            );
                                        }}
                                        isDarkTheme={this.props.isDarkTheme}
                                    >
                                        <OverviewTable<OcrDetectorResult>
                                            evalData={tableData}
                                            columns={columns}
                                            tableTitle={""}
                                            downloadTableTitle={datasetName}
                                            onItemInvoked={this._onItemInvoked}
                                            datasetName={shownDataset}
                                            isDarkTheme={this.props.isDarkTheme}
                                        />
                                    </ExpandCard>
                                </div>
                            );
                        } else {
                            return (
                                <NoDataTip>
                                    No Detection Metrics Generated
                                </NoDataTip>
                            );
                        }
                    })}
                </>
            ) : (
                <NoDataTip>No Detection Metrics Generated </NoDataTip>
            );

        result.push(dataElement);
        return result;
    }

    componentDidMount() {
        super.componentDidMount();

        store.dispatch(
            updateStateAction({
                saveKey: `${store.getState().globalReducer.workSpace}_${
                    Typename.DetectionMetrics
                }_Overview`,
                columns: OVERVIEW_COLUMNS,
            })
        );
    }

    exportAction = () => {
        exportGeneralMetrics(
            this.exportData,
            OVERVIEW_COLUMNS.filter((col) => col.key !== "childrenStat"),
            `DetectionMetrics`
        );
    };

    private _renderAnalysisArea(
        data: [
            string,
            [
                string,
                IWerDataItem<
                    IDataItem<OcrDetectionMetrics>,
                    OcrDetectorResult
                >[]
            ][]
        ][]
    ) {
        const { analysisOn, updateAnalysisState } = this.props;
        const { diffAsPercentage } = this.state;

        const statisticListArr = ANALYSIS_FIELDS.map((field) => {
            return ExtractStatisticListByField(
                data,
                [field] as Array<keyof OcrDetectorResult>,
                diffAsPercentage
            );
        });

        const countData = ANALYSIS_FIELDS.flatMap((field, i) => {
            return AGGREGATED_CATEGORIES.map((n) => {
                const rgSum = QueryCategoryAnalysisCount(
                    statisticListArr[i],
                    SORTED_FIELD,
                    n,
                    AnalysisType.Regression
                );

                const impSum = QueryCategoryAnalysisCount(
                    statisticListArr[i],
                    SORTED_FIELD,
                    n,
                    AnalysisType.Improvement
                );

                return {
                    category: `${field.toUpperCase()} | ${n}`,
                    RG: optimizeDecimalPlaces(rgSum, 1),
                    IMP: optimizeDecimalPlaces(impSum, 1),
                };
            });
        });

        const hideAnalysis = countData.every(
            (d) => isNaN(d.IMP) && isNaN(d.RG)
        );

        return !hideAnalysis ? (
            <>
                <Toggle
                    label={"Analysis"}
                    inlineLabel
                    onText="On"
                    offText="Off"
                    checked={analysisOn}
                    onChange={(_event, checked) => {
                        updateAnalysisState &&
                            updateAnalysisState(
                                MetricsAnalysis.Detection,
                                !!checked
                            );
                    }}
                />
                {!!analysisOn && (
                    <>
                        <DetailsList
                            items={countData}
                            columns={ANALYSIS_COUNT_COLUMNS}
                            selectionMode={SelectionMode.none}
                        ></DetailsList>
                        {this._renderTop20(statisticListArr)}
                    </>
                )}
            </>
        ) : null;
    }

    _renderTop20 = (statisticListArr: StatisticalEntity[][]) => {
        const { diffAsPercentage } = this.state;
        return ANALYSIS_FIELDS.map((field, i) => {
            const topRG_Items = RankCategoriesAnalysis(
                statisticListArr[i],
                SORTED_FIELD,
                AnalysisType.Regression,
                AGGREGATED_CATEGORIES
            );
            const topIMP_Items = RankCategoriesAnalysis(
                statisticListArr[i],
                SORTED_FIELD,
                AnalysisType.Improvement,
                AGGREGATED_CATEGORIES
            );

            let title = "";
            if (topRG_Items.length > 0 && topIMP_Items.length > 0) {
                title = "Regression / Improvement Top 20";
            } else {
                if (topRG_Items.length > 0) {
                    title = "Regression Top 20";
                }
                if (topIMP_Items.length > 0) {
                    title = "Improvement Top 20";
                }
            }

            return (
                <>
                    {(topRG_Items.length > 0 || topIMP_Items.length > 0) && (
                        <>
                            <Stack horizontal>
                                <Label>{`${field.toUpperCase()} ${title}`}</Label>
                                <Toggle
                                    label={"Error Diff as Percentage"}
                                    inlineLabel
                                    onText="On"
                                    offText="Off"
                                    checked={diffAsPercentage}
                                    onChange={(_event, checked) => {
                                        checked !== undefined &&
                                            checked !== diffAsPercentage &&
                                            this.setState({
                                                diffAsPercentage: checked,
                                            });
                                    }}
                                />
                            </Stack>
                            <Stack horizontal>
                                {topRG_Items.length > 0 && (
                                    <FieldAnalysisList
                                        items={topRG_Items}
                                        columns={ANALYSIS_SORTED_COLUMNS_ARR[i]}
                                        diffAsPercentage={diffAsPercentage}
                                        onItemInvoked={
                                            this._onAnalysisItemInvoked
                                        }
                                    ></FieldAnalysisList>
                                )}
                                {topIMP_Items.length > 0 && (
                                    <FieldAnalysisList
                                        items={topIMP_Items}
                                        columns={ANALYSIS_SORTED_COLUMNS_ARR[i]}
                                        diffAsPercentage={diffAsPercentage}
                                        onItemInvoked={
                                            this._onAnalysisItemInvoked
                                        }
                                    ></FieldAnalysisList>
                                )}
                            </Stack>
                        </>
                    )}
                </>
            );
        });
    };

    private _prepareRenderData(matchDatasetVersion: boolean) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );
        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const categories = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const wers = item?.metrics.detector_metrics;
                        return wers ? wers.map((wer) => wer.category) : [];
                    })
                )
            );

            const groupData = categories.map((category) => {
                // retrieve wer data
                const werItems = items.map((item) => {
                    let wer: OcrDetectorResult | undefined;
                    if (
                        item &&
                        item.metrics &&
                        item.metrics.detector_metrics &&
                        item.metrics.detector_metrics.length > 0
                    ) {
                        const { metrics, recordDetail } = item;
                        wer = metrics.detector_metrics.find(
                            (wer) => wer.category === category
                        );
                        if (wer) {
                            wer.datasetName = matchDatasetVersion
                                ? recordDetail.dataset.displayFullName
                                : recordDetail.dataset.displayName;
                            wer = formatPercentDisplay(wer, ["fdr", "fnr"]);
                        }
                    }

                    if (wer === undefined) {
                        wer = {
                            category: category,
                            gtCount: NaN,
                            fnCount: NaN,
                            fnr: NaN,
                            detectedCount: NaN,
                            fdCount: NaN,
                            fdr: NaN,
                            datasetName:
                                (matchDatasetVersion
                                    ? item?.recordDetail.dataset.displayFullName
                                    : item?.recordDetail.dataset.displayName) ??
                                "",
                        };
                    }

                    return {
                        data: item,
                        wer: wer,
                    } as IWerDataItem<
                        IDataItem<OcrDetectionMetrics>,
                        OcrDetectorResult
                    >;
                });
                return [category, werItems] as [
                    string,
                    IWerDataItem<
                        IDataItem<OcrDetectionMetrics>,
                        OcrDetectorResult
                    >[]
                ];
            });

            return [datasetName, groupData] as [
                string,
                [
                    string,
                    IWerDataItem<
                        IDataItem<OcrDetectionMetrics>,
                        OcrDetectorResult
                    >[]
                ][]
            ];
        });
        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private deepLinkHandler(key: string, linkData: any): void {
        if (this.props.setSelectedSubPivot) {
            this.props.setSelectedSubPivot(key, linkData);
        }
    }

    private _onAnalysisItemInvoked(item: StatisticalEntity): void {
        if (item && !AGGREGATED_CATEGORIES.includes(item.name)) {
            let linkData: { [key: string]: string | undefined } = {};
            linkData.toSelectLanguage = item.language;
            linkData.toSelectCategory = item.name;
            this.deepLinkHandler("ByImage", linkData);
        }
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const values = item as any[];
        if (values?.length >= 2) {
            const fieldMetricsArr = values[1] as OcrDetectorResult[];

            if (fieldMetricsArr?.length > 0) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = fieldMetricsArr[0].datasetName;
                linkData.toSelectCategory = fieldMetricsArr[0].category;
                if (
                    AGGREGATED_CATEGORIES.indexOf(linkData.toSelectCategory) ===
                    -1
                ) {
                    this.deepLinkHandler!("ByImage", linkData);
                }
            }
        }
    }
}
