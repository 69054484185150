import * as React from "react";
import {
    Customizer,
    IStackProps,
    ITheme,
    Stack,
    initializeIcons,
    mergeStyles,
    getTheme,
} from "@fluentui/react";
import { FluentStyles } from "@uifabric/fluent-theme";
import { Header } from "../Header";
import { Navigate } from "../Navigate";
import { getFromLocalStorage, saveToLocalStorage } from "../Utils";
import { onThemeChanged } from "./Theme";
import { PORTAL_VER } from "../DataContract";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import { MsalSecurityLoginButton } from "../SecurityLogin/SecurityButton";

// Azure CDN services will retire from the azureedge.net domain by January 15, 2025.
// Fix it by replacing with new CDN url.
// Official Announcement : https://build5nines.com/retirement-of-azureedge-net-dns-edg-io-business-closure-and-what-you-need-to-know/
initializeIcons(
    "https://res.cdn.office.net/files/fabric-cdn-prod_20210407.001/assets/icons/"
);

export interface LayoutProps {
    children?: React.ReactNode;
}

export interface LayoutState {
    isDarkTheme: boolean;
    version: string;
}

export const { Provider, Consumer } = React.createContext(false);

const PAGELIGHT_STYLE = (p: IStackProps, theme: ITheme) => ({
    root: {
        backgroundColor: theme.palette.neutralLighterAlt,
    },
});

const PAGE_STYLES = mergeStyles({
    height: "100vh",
    width: "100vw",
    overflow: "hidden",
    selectors: {
        ":global(body)": {
            padding: 0,
            margin: 0,
        },

        "::-webkit-scrollbar": {
            width: 12,
            height: 12,
        },

        "::-webkit-scrollbar-thumb": {
            border: "4px solid transparent",
            boxShadow: "none",
            backgroundColor: "rgba(189,189,189,0.8)",
            backgroundClip: "padding-box",
        },

        "::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#bdbdbd",
        },

        "::-webkit-scrollbar-track": {
            boxShadow: "none",
        },
    },
});

const theme = getTheme();

export class Layout extends React.Component<LayoutProps, LayoutState> {
    constructor(props: LayoutProps) {
        super(props);

        const isDarkTheme = Boolean(
            getFromLocalStorage("isDarkTheme") === "true"
        );
        this.state = {
            isDarkTheme: isDarkTheme ?? false,
            version: "",
        };
    }
    private _reRenderTheme = () => {
        this.setState((prevState) => {
            saveToLocalStorage("isDarkTheme", String(!prevState.isDarkTheme));
            return { isDarkTheme: !prevState.isDarkTheme };
        });
    };
    public render() {
        const pathname = window.location.pathname;
        const showNav = ![
            "/powerbi",
            "/htmlConvertor",
            "/data",
            "/redisclear",
        ].includes(pathname);
        return (
            <Provider value={this.state.isDarkTheme}>
                <Customizer scopedSettings={FluentStyles}>
                    <Stack
                        verticalFill
                        tokens={{ childrenGap: 10 }}
                        className={PAGE_STYLES}
                        styles={PAGELIGHT_STYLE}
                    >
                        <Header
                            isDarkTheme={this.state.isDarkTheme}
                            onThemeChange={this._reRenderTheme}
                            version={this.state.version}
                        />
                        <Stack
                            horizontal
                            style={{
                                height: "100%",
                                marginTop: 0,
                                overflowY: "hidden",
                            }}
                        >
                            <AuthenticatedTemplate>
                                {showNav && (
                                    <Navigate
                                        isDarkTheme={this.state.isDarkTheme}
                                        onThemeChange={this._reRenderTheme}
                                    ></Navigate>
                                )}
                                <div
                                    style={{
                                        width: "100%",
                                        overflow: `${
                                            showNav ? "hidden" : "auto"
                                        }`,
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "20px",
                                        position: "relative",
                                        backgroundColor: this.state.isDarkTheme
                                            ? theme.palette.black
                                            : theme.palette.white,
                                    }}
                                >
                                    {this.props.children}
                                </div>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                <MsalSecurityLoginButton />
                            </UnauthenticatedTemplate>
                        </Stack>
                    </Stack>
                </Customizer>
            </Provider>
        );
    }

    public componentDidUpdate(
        prevProps: LayoutProps,
        prevState: Readonly<LayoutState>
    ) {
        if (this.state.isDarkTheme !== prevState.isDarkTheme) {
            return <Provider value={this.state.isDarkTheme}></Provider>;
        }
    }
    public componentDidMount() {
        const isDarkTheme = Boolean(
            getFromLocalStorage("isDarkTheme") === "true"
        );
        onThemeChanged(!isDarkTheme);
        fetch("/version.txt")
            .then((response) => response.text())
            .then((version) =>
                this.setState({ version: version }, () => {
                    saveToLocalStorage(PORTAL_VER, version);
                })
            );
    }
}
