import { IChoiceGroupOption } from "@fluentui/react";

import { ColumnValueType, TableColumn } from "../../Controls";

export interface IEntityUnion {
    name: string;
    children: string[];
}

export const ENTITY_GROUP_CONFIG: IEntityUnion[] = [
    {
        name: "TextAnalyticsAPI_EntityGroup",
        children: [
            "TextAnalyticsAPI_Address",
            "TextAnalyticsAPI_DateTime_EntityGroup",
            "TextAnalyticsAPI_Location",
            "TextAnalyticsAPI_Location_GPE",
            "TextAnalyticsAPI_Organization",
            "TextAnalyticsAPI_Person",
            "TextAnalyticsAPI_PhoneNumber",
            "TextAnalyticsAPI_Quantity_EntityGroup",
        ],
    },
    {
        name: "TextAnalyticsAPI_DateTime_EntityGroup",
        children: [
            "TextAnalyticsAPI_DateTime",
            "TextAnalyticsAPI_DateTime_Date",
            "TextAnalyticsAPI_DateTime_Time",
            "TextAnalyticsAPI_DateTime_DateRange",
            "TextAnalyticsAPI_DateTime_TimeRange",
            "TextAnalyticsAPI_DateTime_Duration",
            "TextAnalyticsAPI_DateTime_Set",
        ],
    },
    {
        name: "TextAnalyticsAPI_Quantity_EntityGroup",
        children: [
            "TextAnalyticsAPI_Quantity",
            "TextAnalyticsAPI_Quantity_Number",
            "TextAnalyticsAPI_Quantity_Percentage",
            "TextAnalyticsAPI_Quantity_OrdinalNumbers",
            "TextAnalyticsAPI_Quantity_Age",
            "TextAnalyticsAPI_Quantity_Currency",
            "TextAnalyticsAPI_Quantity_Dimensions",
            "TextAnalyticsAPI_Quantity_Temperature",
        ],
    },
    {
        name: "Invoice_Identifiers_EntityGroup",
        children: [
            "Invoice_PONumber",
            "Invoice_ItemsPO",
            "Invoice_ItemsProductCode",
            "Invoice_InvoiceNumber",
            "Invoice_CustomerID",
        ],
    },
    {
        name: "Invoice_Address_EntityGroup",
        children: [
            "Invoice_BillingAddress",
            "Invoice_CustomerAddress_Other",
            "Invoice_RemittanceAddress",
            "Invoice_ServiceAddress",
            "Invoice_ShippingAddress",
            "Invoice_VendorAddress_Other",
        ],
    },
    {
        name: "Invoice_NarrativeContent_EntityGroup",
        children: ["Invoice_Address_EntityGroup", "Invoice_ItemsDescription"],
    },
    {
        name: "Invoice_Dates_EntityGroup",
        children: [
            "Invoice_InvoiceDate",
            "Invoice_DueDate",
            "Invoice_ItemsDate",
            "Invoice_ServiceEndDate",
            "Invoice_ServiceStartDate",
        ],
    },
    {
        name: "Invoice_Prices_EntityGroup",
        children: [
            "Invoice_ItemsAmount",
            "Invoice_ItemsDiscount",
            "Invoice_ItemsTax",
            "Invoice_ItemsUnitPrice",
            "Invoice_PreviousUnpaidBalance",
            "Invoice_Subtotal",
            "Invoice_TotalAmountDue",
            "Invoice_TotalCurrentCharges",
            "Invoice_TotalTax",
        ],
    },
    {
        name: "Invoice_Names_EntityGroup",
        children: [
            "Invoice_BillingName",
            "Invoice_Customer",
            "Invoice_CustomerName",
            "Invoice_RemittanceName",
            "Invoice_ServiceName",
            "Invoice_ShippingName",
            "Invoice_Vendor",
            "Invoice_VendorName_Other",
        ],
    },
    {
        name: "Receipt_Price_EntityGroup",
        children: [
            "Receipt_Total",
            "Receipt_Subtotal",
            "Receipt_Tax",
            "Receipt_TotalTax",
            "Receipt_TotalPrice",
            "Receipt_Tip",
            "Receipt_GrandTotal",
            "Receipt_PaymentTotal",
            "Receipt_Price",
            "Receipt_SubitemPrice",
            "Receipt_Subtotal",
            "Receipt_TaxAmount",
        ],
    },
    {
        name: "Receipt_DateTime_EntityGroup",
        children: ["Receipt_TransactionDate", "Receipt_TransactionTime"],
    },
    {
        name: "Receipt_Payment_EntityGroup",
        children: [
            "Receipt_PaymentType",
            "Receipt_Amount",
            "Receipt_CardNumber",
            "Receipt_CardTransType",
            "Receipt_TransDate",
            "Receipt_TransTime",
            "Receipt_ApprovalMethod",
            "Receipt_ApprovalCode",
        ],
    },
    {
        name: "DriverLicense_Date_EntityGroup",
        children: [
            "DriverLicense_DateOfBirth",
            "DriverLicense_DateOfExpiration",
        ],
    },
    {
        name: "DriverLicense_Name_EntityGroup",
        children: [
            "DriverLicense_FirstName",
            "DriverLicense_LastName",
            "DriverLicense_FullName",
        ],
    },
    {
        name: "BusinessCard_PhoneNumber_EntityGroup",
        children: [
            "BusinessCard_WorkPhones",
            "BusinessCard_Faxes",
            "BusinessCard_MobilePhones",
        ],
    },
    {
        name: "BusinessCard_Name_EntityGroup",
        children: ["BusinessCard_FirstName", "BusinessCard_LastName"],
    },
    {
        name: "BusinessCard_Titles_EntityGroup",
        children: ["BusinessCard_Title", "BusinessCard_JobTitles"],
    },
    {
        name: "Fillin_EntityGroup",
        children: [
            "Fillin_DotUnderline_EntityGroup",
            "Fillin_Box_EntityGroup",
            "Fillin_Shaded_EntityGroup",
        ],
    },
    {
        name: "Fillin_DotUnderline_EntityGroup",
        children: ["DotUnderline", "DotUnderline_Key", "DotUnderline_Value"],
    },
    {
        name: "Fillin_Box_EntityGroup",
        children: [
            "FullBox",
            "LongHalfBox",
            "ShortHalfBox",
            "BoxedShortHalfBox",
            "FullBox_Key",
            "LongHalfBox_Key",
            "ShortHalfBox_Key",
            "BoxedShortHalfBox_Key",
            "FullBox_Value",
            "LongHalfBox_Value",
            "ShortHalfBox_Value",
            "BoxedShortHalfBox_Value",
        ],
    },
    {
        name: "Fillin_Shaded_EntityGroup",
        children: ["ShadedFillin", "ShadedFillin_Key", "ShadedFillin_Value"],
    },
    {
        name: "InsuranceCard_Descriptive_EntityGroup",
        children: [
            "InsuranceCard_Subscriber.Name",
            "InsuranceCard_Program.Logo",
            "InsuranceCard_Insurer",
            "InsuranceCard_Plan.Type",
            "InsuranceCard_Subscriber.IdNumberSuffix",
            "InsuranceCard_PrescriptionInfo.RxPCN",
            "InsuranceCard_Member.Gender",
            "InsuranceCard_IdNumberSuffix",
            "InsuranceCard_Pbm",
            "InsuranceCard_PrescriptionInfo.RxGrp",
            "InsuranceCard_PrescriptionInfo.RxBIN",
            "InsuranceCard_Member.IdNumberSuffix",
            "InsuranceCard_Pcp.Name",
            "InsuranceCard_Signature",
            "InsuranceCard_Network.Name",
            "InsuranceCard_Benefit",
            "InsuranceCard_Payer.Address",
            "InsuranceCard_Name",
            "InsuranceCard_Member.Employer",
            "InsuranceCard_Plan.Name",
            "InsuranceCard_Member.Name",
            "InsuranceCard_IdNumber.Prefix",
            "InsuranceCard_PrescriptionInfo.RxPlan",
        ],
    },
    {
        name: "InsuranceCard_IDNumber_EntityGroup",
        children: [
            "InsuranceCard_Network.Number",
            "InsuranceCard_PrescriptionInfo.IssuerId",
            "InsuranceCard_Plan.Number",
            "InsuranceCard_IdNumber.Number",
            "InsuranceCard_Subscriber.IdNumber",
            "InsuranceCard_GroupNumber",
            "InsuranceCard_Pcp.Name",
            "InsuranceCard_Payer.Id",
            "InsuranceCard_MedicareMedicaidInfo.Id",
            "InsuranceCard_IdNumber",
        ],
    },
    {
        name: "InsuranceCard_Date_EntityGroup",
        children: [
            "InsuranceCard_MedicareMedicaidInfo.PartBEffectiveDate",
            "InsuranceCard_Member.DateOfBirth",
            "InsuranceCard_MedicareMedicaidInfo.PartAEffectiveDate",
            "InsuranceCard_EffectiveDate",
        ],
    },
    {
        name: "InsuranceCard_PhoneNumber_EntityGroup",
        children: [
            "InsuranceCard_Payer.PhoneNumber",
            "InsuranceCard_Pcp.PhoneNumber",
        ],
    },
];

// prettier-ignore
export const ENTITY_COLUMNS: TableColumn[] = [
    { key: "childrenStat",              name: "",                   fieldName: "childrenStat",             valueType: ColumnValueType.String,   minWidth: 1,     maxWidth: 1,   isResizable: true,  isIconOnly: true,  distinctStr: true },
    { key: "entity",                    name: "Entity",             fieldName: "entity",                   valueType: ColumnValueType.String,   minWidth: 90,    maxWidth: 200, isResizable: true,  isIconOnly: false, distinctStr: true },
    { key: "sentenceCount",             name: "EntityCount",        fieldName: "sentenceCount",            valueType: ColumnValueType.Number,   minWidth: 90,    maxWidth: 220, isResizable: true,  isIconOnly: false, },
    { key: "sentenceDeleteErrorRate",   name: "EntityDeleteRate",   fieldName: "sentenceDeleteErrorRate",  valueType: ColumnValueType.Number,   minWidth: 90,    maxWidth: 220, isResizable: true,  isIconOnly: false, },
    { key: "sentenceSubsErrorRate",     name: "EntitySubsRate",     fieldName: "sentenceSubsErrorRate",    valueType: ColumnValueType.Number,   minWidth: 90,    maxWidth: 220, isResizable: true,  isIconOnly: false, },
    { key: "sentenceErrorRate",         name: "EntityErrorRate",    fieldName: "sentenceErrorRate",        valueType: ColumnValueType.Number,   minWidth: 90,    maxWidth: 220, isResizable: true,  isIconOnly: false, supportSwitchGapUnit: true },
    { key: "sentenceErrorCount",        name: "EntityErrorCount",   fieldName: "sentenceErrorCount",       valueType: ColumnValueType.Number,   minWidth: 90,    maxWidth: 220, isResizable: true,  isIconOnly: false, },
    { key: "wordCount",                 name: "WordCount",          fieldName: "wordCount",                valueType: ColumnValueType.Number,   minWidth: 90,    maxWidth: 120, isResizable: true,  isIconOnly: false, },
    { key: "substitueRate",             name: "SubstituteRate",     fieldName: "substitueRate",            valueType: ColumnValueType.Number,   minWidth: 110,   maxWidth: 185, isResizable: true,  isIconOnly: false, },
    { key: "deleteRate",                name: "DeleteRate",         fieldName: "deleteRate",               valueType: ColumnValueType.Number,   minWidth: 110,   maxWidth: 185, isResizable: true,  isIconOnly: false, },
    { key: "insertRate",                name: "InsertRate",         fieldName: "insertRate",               valueType: ColumnValueType.Number,   minWidth: 110,   maxWidth: 185, isResizable: true,  isIconOnly: false, },
    { key: "error",                     name: "WordErrorRate",      fieldName: "error",                    valueType: ColumnValueType.Number,   minWidth: 140,   maxWidth: 185, isResizable: true,  isIconOnly: false, supportSwitchGapUnit: true},

];

// prettier-ignore
export const ENTITY_POPUP_COLUMNS: TableColumn[] = [
    { key: "datasetName",               name: "Dataset",            fieldName: "datasetName",              valueType: ColumnValueType.String,   minWidth: 80,    maxWidth: 180, isResizable: true,  isIconOnly: false, distinctStr: true },
    { key: "sentenceCount",             name: "EntityCount",        fieldName: "sentenceCount",            valueType: ColumnValueType.Number,   minWidth: 80,    maxWidth: 160, isResizable: true,  isIconOnly: false},
    { key: "sentenceDeleteErrorRate",   name: "EntityDeleteRate",   fieldName: "sentenceDeleteErrorRate",  valueType: ColumnValueType.Number,   minWidth: 80,    maxWidth: 160, isResizable: true,  isIconOnly: false},
    { key: "sentenceSubsErrorRate",     name: "EntitySubsRate",     fieldName: "sentenceSubsErrorRate",    valueType: ColumnValueType.Number,   minWidth: 80,    maxWidth: 160, isResizable: true,  isIconOnly: false},
    { key: "sentenceErrorRate",         name: "EntityErrorRate",    fieldName: "sentenceErrorRate",        valueType: ColumnValueType.Number,   minWidth: 80,    maxWidth: 160, isResizable: true,  isIconOnly: false},
    { key: "sentenceErrorCount",        name: "EntityErrorCount",   fieldName: "sentenceErrorCount",       valueType: ColumnValueType.Number,   minWidth: 80,    maxWidth: 160, isResizable: true,  isIconOnly: false},
];

// prettier-ignore
export const PERENTITY_COLUMNS: TableColumn[] = [
    { key: "datasetName",               name: "Dataset",            fieldName: "datasetName",               valueType: ColumnValueType.String, minWidth: 80,   maxWidth: 180, isResizable: true,  isIconOnly: false, distinctStr: true },
    { key: "sentenceCount",             name: "EntityCount",        fieldName: "sentenceCount",             valueType: ColumnValueType.Number, minWidth: 80,   maxWidth: 100, isResizable: true,  isIconOnly: false},
    { key: "wordCount",                 name: "WordCount",          fieldName: "wordCount",                 valueType: ColumnValueType.Number, minWidth: 80,   maxWidth: 100, isResizable: true,  isIconOnly: false},
    { key: "substitueRate",             name: "SubstituteRate",     fieldName: "substitueRate",             valueType: ColumnValueType.Number, minWidth: 95,   maxWidth: 165, isResizable: true,  isIconOnly: false},
    { key: "deleteRate",                name: "DeleteRate",         fieldName: "deleteRate",                valueType: ColumnValueType.Number, minWidth: 75,   maxWidth: 165, isResizable: true,  isIconOnly: false},
    { key: "insertRate",                name: "InsertRate",         fieldName: "insertRate",                valueType: ColumnValueType.Number, minWidth: 75,   maxWidth: 165, isResizable: true,  isIconOnly: false},
    { key: "sentenceErrorRate",         name: "EntityErrorRate",    fieldName: "sentenceErrorRate",         valueType: ColumnValueType.Number, minWidth: 100,  maxWidth: 165, isResizable: true,  isIconOnly: false},
    { key: "sentenceDeleteErrorRate",   name: "EntityDeleteRate",   fieldName: "sentenceDeleteErrorRate",   valueType: ColumnValueType.Number, minWidth: 150,  maxWidth: 165, isResizable: true,  isIconOnly: false},
    { key: "sentenceSubsErrorRate",     name: "EntitySubsRate",     fieldName: "sentenceSubsErrorRate",     valueType: ColumnValueType.Number, minWidth: 150,  maxWidth: 165, isResizable: true,  isIconOnly: false},
    { key: "error",                     name: "WordErrorRate",      fieldName: "error",                     valueType: ColumnValueType.Number, minWidth: 100,  maxWidth: 165, isResizable: true,  isIconOnly: false},
];

// prettier-ignore
export const DISTRIBUTION_DES_COLUMNS: TableColumn[] = [
    { key: "tag",                     name: "Error Tag",    fieldName: "tag",           valueType: ColumnValueType.String,      minWidth: 80,   maxWidth: 180,  isResizable: true,  isIconOnly: false,  distinctStr:true, },
    { key: "dataset",                 name: "Dataset",      fieldName: "dataset",       valueType: ColumnValueType.String,      minWidth: 100,  maxWidth: 300,  isResizable: true,  isIconOnly: false,  distinctStr:true, },
    { key: "error_count",             name: "Count",        fieldName: "error_count",   valueType: ColumnValueType.Number,      minWidth: 80,   maxWidth: 180,  isResizable: true,  isIconOnly: false},
];

// prettier-ignore
export const DISTRIBUTION_COLUMNS: TableColumn[] = [
    { key: "childrenStat",            name: "",             fieldName: "childrenStat",   valueType: ColumnValueType.String,          minWidth: 20,   maxWidth: 20,  isResizable: true,  isIconOnly: true,    distinctStr:true, },
    { key: "tag",                     name: "Error Tag",    fieldName: "tag",            valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 250, isResizable: true,  isIconOnly: false,   distinctStr:true,},
    { key: "error_count",             name: "Count",        fieldName: "error_count",    valueType: ColumnValueType.Number,          minWidth: 80,   maxWidth: 180, isResizable: true,  isIconOnly: false,   },
];

// prettier-ignore
export const IMAGECOLUMNS: TableColumn[] = [
    { key: "imageId",           name: "ImageId",              fieldName: "imageId",            isKey: true,  valueType: ColumnValueType.String, minWidth: 55,  maxWidth: 110, },
    { key: "Category",          name: "Category",             fieldName: "Category",           isKey: false, valueType: ColumnValueType.String, minWidth: 60,  maxWidth: 120, distinctStr: true},
    { key: "WordCount",         name: "WordCount",            fieldName: "WordCount",          isKey: false, valueType: ColumnValueType.Number, minWidth: 80,  maxWidth: 90, },
    { key: "SentenceCount",     name: "EntityCount",          fieldName: "SentenceCount",      isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 160, },
    { key: "SentenceErrorRate", name: "EntityErrorRate (%)",  fieldName: "SentenceErrorRate",  isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 160, supportSwitchGapUnit:true},
    { key: "EntityErrorCount",  name: "EntityErrorCount",     fieldName: "EntityErrorCount",   isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 160, },
    { key: "InsertCount",       name: "WordInsertCount",      fieldName: "InsertCount",        isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 160, filterName: "Show insert error (>0) lines", },
    { key: "SubstitueCount",    name: "WordSubstitueCount",   fieldName: "SubstitueCount",     isKey: false, valueType: ColumnValueType.Number, minWidth: 200, maxWidth: 250, filterName: "Show subs error (>0) lines",   },
    { key: "DeleteCount",       name: "WordDeleteCount",      fieldName: "DeleteCount",        isKey: false, valueType: ColumnValueType.Number, minWidth: 100, maxWidth: 160, filterName: "Show delete error (>0) lines", },
    { key: "Error",             name: "WordErrorRate (%)",    fieldName: "Error",              isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 160, supportSwitchGapUnit:true},
    { key: "WordErrorCount",    name: "WordErrorCount",       fieldName: "WordErrorCount",     isKey: false, valueType: ColumnValueType.Number, minWidth: 150, maxWidth: 160, },

];

// prettier-ignore
export const LINECOLUMNS: TableColumn[] = [
    { key: "textline_id",   name: "TextlineID",         fieldName: "textline_id",   isKey: true,  valueType: ColumnValueType.String,   minWidth: 200, maxWidth: 400, },
    { key: "category",      name: "Category",           fieldName: "category",      isKey: false, valueType: ColumnValueType.String,   minWidth: 100, maxWidth: 200, },
    { key: "insert_error",  name: "InsertError",        fieldName: "insert_error",  isKey: false, valueType: ColumnValueType.Number,   minWidth: 100, maxWidth: 200, filterName: "Show insert error (>0) lines", },
    { key: "delete_error",  name: "DeleteError",        fieldName: "delete_error",  isKey: false, valueType: ColumnValueType.Number,   minWidth: 100, maxWidth: 200, filterName: "Show delete error (>0) lines", },
    { key: "subs_error",    name: "SubError",           fieldName: "subs_error",    isKey: false, valueType: ColumnValueType.Number,   minWidth: 100, maxWidth: 200, filterName: "Show subs error (>0) lines", },
    { key: "total_error",   name: "TotalError",         fieldName: "total_error",   isKey: false, valueType: ColumnValueType.Number,   minWidth: 100, maxWidth: 200, },
    { key: "ref",           name: "REF",                fieldName: "ref",           isKey: false, valueType: ColumnValueType.String,   minWidth: 100, maxWidth: 200, },
    { key: "hyp",           name: "HYP",                fieldName: "hyp",           isKey: false, valueType: ColumnValueType.String,   minWidth: 100, maxWidth: 200, },
    { key: "eval",          name: "Eval",               fieldName: "eval",          isKey: false, valueType: ColumnValueType.String,   minWidth: 100, maxWidth: 200, },
    { key: "entity_name",   name: "EntityName",         fieldName: "entity_name",   isKey: false, valueType: ColumnValueType.String,   minWidth: 100, maxWidth: 200, },
    { key: "entity_pred",   name: "EntityPrediction",   fieldName: "entity_pred",   isKey: false, valueType: ColumnValueType.String,   minWidth: 100, maxWidth: 200, },
    { key: "tags",          name: "Tags",               fieldName: "tags",          isKey: false, valueType: ColumnValueType.String,   minWidth: 100, maxWidth: 200,type:ColumnValueType.List },
];

// prettier-ignore
export const PREDICTION_COLUMNS: TableColumn[] = [
    { key: "pred_entity",             name: "EntityPrediction​",    fieldName: "pred_entity",    valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 250, isResizable: true,  isIconOnly: false ,  distinctStr:true,},
    { key: "pred_count",              name: "Count",               fieldName: "pred_count",     valueType: ColumnValueType.Number,          minWidth: 80,   maxWidth: 180, isResizable: true,  isIconOnly: false},
];

// prettier-ignore
export const CLM_COLUMNS: TableColumn[] = [
    { key: "clm_status",             name: "ClmStatus",           fieldName: "clm_status",    valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 250, isResizable: true,  isIconOnly: false ,  distinctStr:true,},
    { key: "count",                  name: "Count",               fieldName: "count",         valueType: ColumnValueType.Number,          minWidth: 80,   maxWidth: 180, isResizable: true,  isIconOnly: false},
];

// prettier-ignore
export const PREDICTION_DES_COLUMNS: TableColumn[] = [
    { key: "pred_entity",             name: "EntityPrediction​",    fieldName: "pred_entity",    valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 250,  isResizable: true,  isIconOnly: false ,  distinctStr:true,},
    { key: "dataset",                 name: "Dataset",             fieldName: "dataset",        valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 300,  isResizable: true,  isIconOnly: false,   distinctStr:true, },
    { key: "pred_count",              name: "Count",               fieldName: "pred_count",     valueType: ColumnValueType.Number,          minWidth: 80,   maxWidth: 180,  isResizable: true,  isIconOnly: false},
];

// prettier-ignore
export const CLM_DES_COLUMNS: TableColumn[] = [
    { key: "clm_status",              name: "ClmStatus",           fieldName: "clm_status",     valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 250,  isResizable: true,  isIconOnly: false ,  distinctStr:true,},
    { key: "dataset",                 name: "Dataset",             fieldName: "dataset",        valueType: ColumnValueType.String,          minWidth: 100,  maxWidth: 300,  isResizable: true,  isIconOnly: false,   distinctStr:true, },
    { key: "pred_count",              name: "Count",               fieldName: "pred_count",     valueType: ColumnValueType.Number,          minWidth: 80,   maxWidth: 180,  isResizable: true,  isIconOnly: false},
]

// prettier-ignore
export const PREDICTIONLINECOLUMNS: TableColumn[] = [
    { key: "textline_id",         name: "TextlineID",         fieldName: "textline_id",         isKey: true,  valueType: ColumnValueType.String,   minWidth: 400, maxWidth: 600, },
    { key: "category",            name: "Category",           fieldName: "category",            isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
    { key: "ref",                 name: "REF",                fieldName: "ref",                 isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
    { key: "hyp",                 name: "HYP",                fieldName: "hyp",                 isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
    { key: "entity_name",         name: "EntityName",         fieldName: "entity_name",         isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
    { key: "entity_pred",         name: "EntityPrediction",   fieldName: "entity_pred",         isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
    { key: "entity_pred_result",  name: "PredResult",         fieldName: "entity_pred_result",  isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
    { key: "clm_status",          name: "ClmStatus",          fieldName: "clm_status",          isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
    { key: "clm_result",          name: "ClmResult",          fieldName: "clm_result",          isKey: false, valueType: ColumnValueType.String,   minWidth: 150, maxWidth: 200, },
];

export const LINE_DELETION = [
    "FILTERED",
    "EARLY_REJ",
    "REJECTED",
    "DETECTOR_DELETE",
    "DETECTOR_INACCURATE",
];
export const SIMILAR_CHAR = "SIMILAR_CHARS_";

export const GROUP_TAGS = {
    LINE_DELETION: "LINE_DELETION",
    SIMILAR_CHAR: "SIMILAR_CHAR",
};

export const DATASET_NAME_SYMBOLS = {
    //For OCR Printed, it's normal type and leave empty. Mar 31, 2022.
    OCR_Printed: "",
    OCR_Handwritten: "_hw:",
    Entity: "Entity_",
    Verticals: "Vertical_",
};

export const CHOICE_OPTIONS: IChoiceGroupOption[] = [
    {
        key: DATASET_NAME_SYMBOLS.Verticals,
        text: "Verticals",
    },
    {
        key: DATASET_NAME_SYMBOLS.Entity,
        text: "Entity",
    },
    {
        key: DATASET_NAME_SYMBOLS.OCR_Printed,
        text: "OCR Printed",
    },
    {
        key: DATASET_NAME_SYMBOLS.OCR_Handwritten,
        text: "OCR Handwritten",
    },
];

export const DEFAULT_ENTITY_COLUMNS = [
    "entity",
    "sentenceCount",
    "sentenceDeleteErrorRate",
    "sentenceSubsErrorRate",
    "sentenceErrorRate",
    "sentenceErrorCount",
];
