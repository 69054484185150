import * as React from "react";
import "./FieldAnalysisList.scss";
import {
    ConstrainMode,
    DetailsList,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    TooltipHost,
} from "@fluentui/react";
import { ColumnValueType, NumContrastPolicy, TableColumn } from "./TableList";
import { DetailsList_onRenderRow_InterlaceColor } from "../Utils/LibraryOverwriteAndExtension";

interface IProps {
    items: any[];
    columns: TableColumn[];
    diffAsPercentage: boolean;
    onItemInvoked?: (item?: any, index?: number, ev?: Event) => void;
}

export class FieldAnalysisList extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);

        this._onRenderItemColumn = this._onRenderItemColumn.bind(this);
    }
    render(): React.ReactNode {
        const { items, columns, onItemInvoked } = this.props;
        return (
            <DetailsList
                items={items}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
                constrainMode={ConstrainMode.unconstrained}
                onRenderRow={DetailsList_onRenderRow_InterlaceColor}
                onItemInvoked={onItemInvoked}
                onRenderItemColumn={this._onRenderItemColumn}
            ></DetailsList>
        );
    }

    private _onRenderItemColumn(
        item: any,
        _index?: number | undefined,
        column?: IColumn | undefined
    ): React.ReactNode {
        const tableCol = column as TableColumn;
        if (item && tableCol.fieldName) {
            const fieldItem = item[tableCol.fieldName];
            switch (tableCol.valueType) {
                case ColumnValueType.Tuple: {
                    const valuesPropName =
                        StatsCol.GetDiffPropNameByValuesPropName(
                            tableCol.fieldName
                        );
                    const [a, b] = fieldItem as [number, number];
                    const diff = item[valuesPropName] as number;
                    return (
                        <>
                            <span className="analysisVal">{a}</span>
                            {this._renderDiffSpan(
                                diff,
                                tableCol.contrastPolicy,
                                tableCol.supportSwitchGapUnit
                            )}
                            <span className="analysisVal">{b}</span>
                        </>
                    );
                }
                case ColumnValueType.String: {
                    return (
                        <TooltipHost content={fieldItem as string}>
                            {fieldItem}
                        </TooltipHost>
                    );
                }

                default:
                    return <>{fieldItem}</>;
            }
        } else {
            return <></>;
        }
    }

    private _renderDiffSpan(
        diff: number,
        contrastPolicy?: NumContrastPolicy,
        supportSwitchGapUnit?: boolean
    ) {
        const { diffAsPercentage } = this.props;
        if (
            diff !== 0 &&
            contrastPolicy === NumContrastPolicy.PositiveRed_NegativeGreen
        ) {
            return (
                <span
                    className="diffStyle"
                    style={{
                        color: diff > 0 ? "red" : "green",
                    }}
                >
                    {`(${diff > 0 ? "+" : ""}${
                        !!diffAsPercentage && !!supportSwitchGapUnit
                            ? `${diff.toString()}%`
                            : diff.toString()
                    })`}
                </span>
            );
        } else if (
            diff !== 0 &&
            contrastPolicy === NumContrastPolicy.PositiveGreen_NegativeRed
        ) {
            return (
                <span
                    className="diffStyle"
                    style={{
                        color: diff < 0 ? "red" : "green",
                    }}
                >
                    {`(${diff > 0 ? "+" : ""}${
                        !!diffAsPercentage
                            ? `${diff.toString()}%`
                            : diff.toString()
                    })`}
                </span>
            );
        } else {
            return (
                <span className="analysisVal">
                    {!!diffAsPercentage
                        ? `${diff.toString()}%`
                        : diff.toString()}
                </span>
            );
        }
    }
}

export class StatsCol {
    private static readonly _diff = "diff";
    private static readonly _values = "values";

    public static DiffPropName(index: number) {
        return `${this._diff}${index}`;
    }
    public static ValuesPropName(index: number) {
        return `${this._values}${index}`;
    }

    public static GetDiffPropNameByValuesPropName(valPropName: string) {
        return valPropName.replace(this._values, this._diff);
    }
}
