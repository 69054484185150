import { from } from "linq-to-typescript";
import { IColumn } from "@fluentui/react";
import { IDataItem } from "../../DataContract";
import { StatsCol } from "../../Controls/FieldAnalysisList";
import {
    CheckStringWhetherFollowPolicy,
    optimizeDecimalPlaces,
    StringCheckPolicy,
} from "../../Utils";

export enum MetricsAnalysis {
    General,
    Detection,
}

export enum AnalysisType {
    Regression,
    Improvement,
}

// prettier-ignore
export const ANALYSIS_COUNT_COLUMNS: IColumn[] = [
    { key: "category",  name: "Category",                    fieldName: "category",  minWidth: 200,  maxWidth: 400,  isResizable: true, },
    { key: "RG",        name: "Regression Language Count",   fieldName: "RG",        minWidth: 100,  maxWidth: 200,  isResizable: true, },
    { key: "IMP",       name: "Improvement Language Count",  fieldName: "IMP",       minWidth: 100,  maxWidth: 200,  isResizable: true, },
]

export interface IWerDataItem<T, K> {
    data?: IDataItem<T>;
    wer: K;
}
export interface TransformedDataItem<T, K> {
    data?: IDataItem<T>;
    renderItem: K;
}

export interface StatisticalEntity {
    language: string;
    name: string;
    [key: string]: string | number | [number, number];
}

export function ExtractStatisticListByField<T, K>(
    dataSrc: [string, [string, IWerDataItem<T, K>[]][]][],
    fields: Array<keyof K>,
    diffAsPercentage: boolean = false
): StatisticalEntity[] {
    const statisticList = dataSrc.flatMap(([lang, categoryArr]) => {
        return categoryArr.map(([name, itemsArr]) => {
            const obj: any = {};
            fields.forEach((field, i) => {
                const val0 = itemsArr[0].wer[field] as unknown as number;
                const val1 = itemsArr[1].wer[field] as unknown as number;
                obj[StatsCol.ValuesPropName(i)] = [val0, val1];
                obj[StatsCol.DiffPropName(i)] = optimizeDecimalPlaces(
                    !!diffAsPercentage && field === "error"
                        ? ((val0 - val1) / val1) * 100
                        : val0 - val1,
                    2
                );
            });

            const objStr = JSON.stringify(obj);
            return {
                language: lang,
                name: name,
                ...JSON.parse(objStr),
            } as StatisticalEntity;
        });
    });

    return statisticList;
}

export function RankCategoriesAnalysis(
    data: StatisticalEntity[],
    fieldName: string,
    analysisType: AnalysisType,
    ignoredCategories: string[] = [],
    ignoredLanguagePolicies: StringCheckPolicy[] = [],
    top: number = 20
): StatisticalEntity[] {
    const sortedData =
        analysisType === AnalysisType.Regression
            ? from(data)
                  .where(
                      (s) =>
                          !ignoredCategories.includes(s.name) &&
                          !ignoredLanguagePolicies.some((p) =>
                              CheckStringWhetherFollowPolicy(s.language, p)
                          ) &&
                          (s[fieldName] as number) > 0
                  )
                  .orderByDescending(
                      (s) => s[fieldName] as number,
                      (x, y) => x - y
                  )
            : from(data)
                  .where(
                      (s) =>
                          !ignoredCategories.includes(s.name) &&
                          !ignoredLanguagePolicies.some((p) =>
                              CheckStringWhetherFollowPolicy(s.language, p)
                          ) &&
                          (s[fieldName] as number) < 0
                  )
                  .orderBy(
                      (s) => s[fieldName] as number,
                      (x, y) => x - y
                  );
    return sortedData.take(top).toArray();
}

export function QueryCategoryAnalysisCount(
    data: StatisticalEntity[],
    fieldName: string,
    category: string,
    analysisType: AnalysisType
): number {
    const iEnumData = from(data);
    const analysisCount = iEnumData
        .where(
            (s) =>
                s.name === category &&
                (analysisType === AnalysisType.Regression
                    ? (s[fieldName] as number) > 0
                    : (s[fieldName] as number) < 0)
        )
        .count();

    if (analysisCount === 0) {
        const countOfItems = iEnumData
            .where((s) => s.name === category)
            .count();

        const countOfNaN = iEnumData
            .where((s) => s.name === category && Number.isNaN(s[fieldName]))
            .count();

        if (countOfItems === countOfNaN) {
            return NaN;
        }
    }

    return analysisCount;
}
