import React, { Fragment } from "react";
import "../Common/MetricStyle.scss";
import {
    Label,
    Stack,
    Toggle,

    //Detail list
    DetailsList,
    SelectionMode,

    //Document Card
    DocumentCard,
    DocumentCardDetails,
    DocumentCardTitle,
    DocumentCardType,

    //MessageBar
    MessageBar,
    MessageBarType,
} from "@fluentui/react";

import {
    DatasetSet,
    IDataItem,
    StringCheckMode,
    Typename,
} from "../../DataContract";
import {
    CommonView,
    ICommonProps,
    ICommonState,
} from "../Common/CommonMetrics";
import {
    VerticalBarChart,
    OverviewTable,
    TableColumn,
    ColumnValueType,
    NumContrastPolicy,
} from "../../Controls";
import {
    getColorByIndex,
    optimizeDecimalPlaces,
    StringCheckPolicy,
} from "../../Utils";
import { FieldAnalysisList, StatsCol } from "../../Controls/FieldAnalysisList";
import {
    AnalysisType,
    ANALYSIS_COUNT_COLUMNS,
    ExtractStatisticListByField,
    IWerDataItem,
    MetricsAnalysis,
    QueryCategoryAnalysisCount,
    RankCategoriesAnalysis,
    StatisticalEntity,
} from "./OcrDataContract";
import { ExpandCard } from "../../Controls/Common/ExpandCard";
import { exportGeneralMetrics } from "../../Utils/ExportFile";
import { store } from "../../../store";
import { updateStateAction } from "../../../store/reducers/setting";
import { FullScreen } from "../Common/FullScreen";

interface OcrWerResult {
    category: string;
    sentenceCount: number;
    wordCount: number;
    correct: number;
    substitueRate: number;
    deleteRate: number;
    insertRate: number;
    error: number;
    errorCount: number;
    sentenceErrorRate: number;
    datasetName: string;
}

interface OcrGeneralMetrics {
    category_wer: Array<OcrWerResult>;
    category_cer: Array<OcrWerResult>;
}
const SORTED_FIELD: string = StatsCol.DiffPropName(0);
const ANALYSIS_FIELDS: Array<keyof OcrWerResult> = ["errorCount", "error"];
const DISPLAY_FIELDS: Array<string> = ANALYSIS_FIELDS.map((_, i) =>
    StatsCol.ValuesPropName(i)
);

const AGGREGATED_CATEGORIES = [
    "Overall Categories",
    "Documentation Categories",
];

// prettier-ignore
const IGNORED_LANGUAGES_POLICIES: StringCheckPolicy[] = [
    { symbol: "Coverage",   mode: StringCheckMode.Includes },
    { symbol: "Unofficial", mode: StringCheckMode.Includes },
    { symbol: "Entity_",    mode: StringCheckMode.StartsWith },
    { symbol: "Vertical",   mode: StringCheckMode.StartsWith },
];

// prettier-ignore
const ANALYSIS_SORTED_COLUMNS: TableColumn[]= [
    { key: "language",          name: "Language",      fieldName: "language",          minWidth: 110,  maxWidth: 150, isResizable: true, valueType:ColumnValueType.String },
    { key: "name",              name: "Category",      fieldName: "name",              minWidth: 80,   maxWidth: 150, isResizable: true, valueType:ColumnValueType.String },
    { key: DISPLAY_FIELDS[0],   name: `Error Count`,   fieldName: DISPLAY_FIELDS[0],   minWidth: 120,  maxWidth: 200, isResizable: true, valueType:ColumnValueType.Tuple, contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen },
    { key: DISPLAY_FIELDS[1],   name: `Error`,         fieldName: DISPLAY_FIELDS[1],   minWidth: 120,  maxWidth: 200, isResizable: true, valueType:ColumnValueType.Tuple, contrastPolicy: NumContrastPolicy.PositiveRed_NegativeGreen, supportSwitchGapUnit:true },
];

// prettier-ignore
const OVERVIEW_COLUMNS: TableColumn[] = [
    { key: "category",          name: "Category",           fieldName: "category",           valueType: ColumnValueType.String,  minWidth: 100,   maxWidth: 200, isResizable: true, distinctStr: true },
    { key: "sentenceCount",     name: "SentenceCount",      fieldName: "sentenceCount",      valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "wordCount",         name: "WordCount",          fieldName: "wordCount",          valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 200, isResizable: true, },
    { key: "substitueRate",     name: "SubstitueRate",      fieldName: "substitueRate",      valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
    { key: "deleteRate",        name: "DeleteRate",         fieldName: "deleteRate",         valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
    { key: "insertRate",        name: "InsertRate",         fieldName: "insertRate",         valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
    { key: "error",             name: "Error",              fieldName: "error",              valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, supportSwitchGapUnit: true},
    { key: "errorCount",        name: "ErrorCount",         fieldName: "errorCount",         valueType: ColumnValueType.Number,  minWidth: 100,   maxWidth: 250, isResizable: true, },
    { key: "sentenceErrorRate", name: "SentenceErrorRate",  fieldName: "sentenceErrorRate",  valueType: ColumnValueType.Number,  minWidth: 150,   maxWidth: 250, isResizable: true, },
];

export interface IProps extends ICommonProps {
    analysisOn?: boolean;
    updateAnalysisState?: (
        metricsAnalysis: MetricsAnalysis,
        isOn: boolean
    ) => void;
}

interface IState extends ICommonState<OcrGeneralMetrics> {
    dataItems: IDataItem<OcrGeneralMetrics>[];
    diffAsPercentage: boolean;
    linkData?: any;
}

export class GeneralMetricsOverivew extends CommonView<
    IProps,
    IState,
    OcrGeneralMetrics
> {
    constructor(props: IProps) {
        super(props);

        this._onColumnDropDownChange = this._onColumnDropDownChange.bind(this);
        this.deepLinkHandler = this.deepLinkHandler.bind(this);
        this._onAnalysisItemInvoked = this._onAnalysisItemInvoked.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.state = {
            dataItems: [],
            expandItem: new Map<number, boolean>(),
            matchDatasetVersion: true,
            diffAsPercentage: false,
            viewType: store.getState().globalReducer.viewType,
        };
    }

    public render() {
        const { viewType } = this.state;

        return (
            <>
                <MessageBar
                    dismissButtonAriaLabel="Close"
                    messageBarType={MessageBarType.info}
                    messageBarIconProps={{ iconName: "InfoSolid" }}
                    styles={{
                        root: {
                            backgroundColor: "#eff6fc",
                        },
                        icon: {
                            color: "#0078d4",
                        },
                    }}
                >
                    <b>Documentation Categories include:&nbsp;&nbsp;</b>
                    Document | Invoice | Receipt | EyeChart | Table |
                    CharacterBox | BankChecks | TravelerEntryForms |
                    BillsReceipts | Applications | MedicalPrescriptions |
                    Invoices | Contracts | Tables
                </MessageBar>

                <FullScreen>
                    {viewType === "Table" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsTable()}
                        </div>
                    )}

                    {viewType === "Chart" && (
                        <div
                            style={{ height: "100%", overflow: "hidden auto" }}
                        >
                            {this._renderAsChart()}
                        </div>
                    )}
                </FullScreen>
            </>
        );
    }

    queryMetricsResult() {
        this._queryMetricsResult("basic_metrics.json");
    }

    componentDidMount() {
        super.componentDidMount();
        store.dispatch(
            updateStateAction({
                columns: OVERVIEW_COLUMNS,
                saveKey: `${
                    this.props.saveSetKey ??
                    `${store.getState().globalReducer.workSpace}_${
                        Typename.GeneralMetrics
                    }`
                }_Overivew`,
            })
        );
    }

    exportAction = () => {
        exportGeneralMetrics(
            this.exportData,
            OVERVIEW_COLUMNS,
            "GeneralMetricsOverview"
        );
    };

    private _renderAsTable() {
        const { records } = this.props;

        const { selectedColumns, matchDatasetVersion, expandItem } = this.state;

        let columnList: any[] = OVERVIEW_COLUMNS;

        const columns: TableColumn[] = columnList.filter(
            (value) =>
                selectedColumns?.findIndex((col) => col === value.key) !== -1
        );

        const data = this._prepareRenderData(matchDatasetVersion!);
        this.exportData = data;

        const result: JSX.Element[] = [];
        if (records.length === 2) {
            const analysisElements = this._renderAnalysisArea(data);
            if (analysisElements) {
                result.push(analysisElements);
            }
        }

        const tableArr = data.map(([datasetName, groupData], index) => {
            datasetName =
                datasetName.slice(0, datasetName.indexOf(":")) +
                "    Dataset: " +
                datasetName.slice(
                    datasetName.indexOf(":") + 1,
                    datasetName.length
                );
            const tableData = groupData.map(([_, werItems]) => {
                return werItems.map((werItem) => werItem.wer);
            });

            return (
                <div className="overview__detail" key={`table_${datasetName}`}>
                    {this.props.alwaysShow ? (
                        <OverviewTable<OcrWerResult>
                            evalData={tableData}
                            columns={columns}
                            tableTitle={datasetName}
                            downloadTableTitle={datasetName}
                            onItemInvoked={this._onItemInvoked}
                            isDarkTheme={this.props.isDarkTheme}
                            showDiffTypeToggle={true}
                        />
                    ) : (
                        <ExpandCard
                            text={datasetName}
                            expandAll={
                                expandItem?.get(index) ??
                                store.getState().globalReducer.expandAll
                            }
                            onExpandChange={(expand: boolean) => {
                                expandItem?.set(index, expand);
                                this.setState({ expandItem: expandItem }, () =>
                                    this._expandCountChange(data.length)
                                );
                            }}
                            isDarkTheme={this.props.isDarkTheme}
                        >
                            <OverviewTable<OcrWerResult>
                                evalData={tableData}
                                columns={columns}
                                tableTitle={""}
                                downloadTableTitle={datasetName}
                                onItemInvoked={this._onItemInvoked}
                                isDarkTheme={this.props.isDarkTheme}
                                showDiffTypeToggle={true}
                            />
                        </ExpandCard>
                    )}
                </div>
            );
        });

        result.push(...tableArr);
        return result;
    }

    private _renderAnalysisArea(
        data: [
            string,
            [
                string,
                IWerDataItem<IDataItem<OcrGeneralMetrics>, OcrWerResult>[]
            ][]
        ][]
    ) {
        const { analysisOn, updateAnalysisState } = this.props;
        const { diffAsPercentage } = this.state;

        const statisticList = ExtractStatisticListByField(
            data,
            ANALYSIS_FIELDS,
            diffAsPercentage
        );
        const countData = AGGREGATED_CATEGORIES.map((n) => {
            const rgSum = QueryCategoryAnalysisCount(
                statisticList,
                SORTED_FIELD,
                n,
                AnalysisType.Regression
            );

            const impSum = QueryCategoryAnalysisCount(
                statisticList,
                SORTED_FIELD,
                n,
                AnalysisType.Improvement
            );

            return {
                category: `Error | ${n}`,
                RG: optimizeDecimalPlaces(rgSum, 1),
                IMP: optimizeDecimalPlaces(impSum, 1),
            };
        });

        const hideAnalysis = countData.every(
            (d) => isNaN(d.IMP) && isNaN(d.RG)
        );

        return !hideAnalysis ? (
            <>
                <Toggle
                    label={"Analysis"}
                    inlineLabel
                    onText="On"
                    offText="Off"
                    checked={analysisOn}
                    onChange={(_event, checked) => {
                        updateAnalysisState &&
                            updateAnalysisState(
                                MetricsAnalysis.General,
                                !!checked
                            );
                    }}
                />
                {!!analysisOn && (
                    <>
                        <DetailsList
                            items={countData}
                            columns={ANALYSIS_COUNT_COLUMNS}
                            selectionMode={SelectionMode.none}
                        ></DetailsList>
                        {this._renderTop20(statisticList)}
                    </>
                )}
            </>
        ) : null;
    }
    _renderTop20 = (statisticList: StatisticalEntity[]) => {
        const { diffAsPercentage } = this.state;
        const topRG_Items = RankCategoriesAnalysis(
            statisticList,
            SORTED_FIELD,
            AnalysisType.Regression,
            AGGREGATED_CATEGORIES,
            IGNORED_LANGUAGES_POLICIES
        );
        const topIMP_Items = RankCategoriesAnalysis(
            statisticList,
            SORTED_FIELD,
            AnalysisType.Improvement,
            AGGREGATED_CATEGORIES,
            IGNORED_LANGUAGES_POLICIES
        );
        let title = "";
        if (topRG_Items.length > 0 && topIMP_Items.length > 0) {
            title = "WER Regression / Improvement Top 20";
        } else {
            if (topRG_Items.length > 0) {
                title = "WER Regression Top 20";
            }
            if (topIMP_Items.length > 0) {
                title = "WER Improvement Top 20";
            }
        }

        return (
            <>
                {(topRG_Items.length > 0 || topIMP_Items.length > 0) && (
                    <>
                        <Stack horizontal>
                            <Label>{title} |</Label>
                            <Toggle
                                label={"Error Diff as Percentage"}
                                inlineLabel
                                onText="On"
                                offText="Off"
                                checked={diffAsPercentage}
                                onChange={(_event, checked) => {
                                    checked !== undefined &&
                                        checked !== diffAsPercentage &&
                                        this.setState({
                                            diffAsPercentage: checked,
                                        });
                                }}
                            />
                        </Stack>
                        <Stack horizontal>
                            {topRG_Items.length > 0 && (
                                <FieldAnalysisList
                                    items={topRG_Items}
                                    columns={ANALYSIS_SORTED_COLUMNS}
                                    diffAsPercentage={diffAsPercentage}
                                    onItemInvoked={this._onAnalysisItemInvoked}
                                ></FieldAnalysisList>
                            )}
                            {topIMP_Items.length > 0 && (
                                <FieldAnalysisList
                                    items={topIMP_Items}
                                    columns={ANALYSIS_SORTED_COLUMNS}
                                    diffAsPercentage={diffAsPercentage}
                                    onItemInvoked={this._onAnalysisItemInvoked}
                                ></FieldAnalysisList>
                            )}
                        </Stack>
                    </>
                )}
            </>
        );
    };

    private _renderAsChart() {
        const { records } = this.props;
        const { matchDatasetVersion } = this.state;
        const data = this._prepareRenderData(matchDatasetVersion!);
        const result: JSX.Element[] = [];
        if (records.length === 2) {
            const analysisElements = this._renderAnalysisArea(data);
            if (analysisElements) {
                result.push(analysisElements);
            }
        }

        const chartElementArr = data.map(([datasetName, groupData], index) => {
            datasetName =
                datasetName.slice(0, datasetName.indexOf(":")) +
                "    Dataset: " +
                datasetName.slice(
                    datasetName.indexOf(":") + 1,
                    datasetName.length
                );
            const chartData = groupData.map(([category, werItems]) => {
                const series = werItems.map((werItem, werIndex) => {
                    const wer = isNaN(werItem.wer.error)
                        ? 0
                        : werItem.wer.error;
                    return {
                        key: `series_${werIndex}`,
                        data: wer,
                        color: getColorByIndex(werIndex),
                        legend: werItem.data?.recordDetail.name ?? "",
                    };
                });
                return {
                    name: category,
                    series: series,
                };
            });

            return (
                <Fragment key={`chart_${datasetName}`}>
                    <DocumentCard
                        key={`chart_${datasetName}`}
                        className="overview__card"
                        type={DocumentCardType.compact}
                    >
                        <DocumentCardDetails>
                            <DocumentCardTitle
                                className="overview__title"
                                title={datasetName}
                            />
                            <VerticalBarChart
                                id={"root" + index.toString()}
                                height={600}
                                data={chartData}
                                isDarkTheme={this.props.isDarkTheme}
                            ></VerticalBarChart>
                        </DocumentCardDetails>
                    </DocumentCard>
                </Fragment>
            );
        });

        if (chartElementArr.length > 0) {
            result.push(...chartElementArr);
        }

        return result;
    }

    private _prepareRenderData(matchDatasetVersion: boolean) {
        // find all datasets
        const datasets = Array.from(
            new DatasetSet(this.props.records.flatMap((r) => r.getDatasets()))
        );

        const allDatasetNames = Array.from(
            new Set(
                datasets.map((dataset) =>
                    matchDatasetVersion
                        ? dataset.displayFullName
                        : dataset.displayName
                )
            )
        );
        const datasetNames = this._filterDatasetsForReport(allDatasetNames);

        const data = datasetNames.map((datasetName) => {
            const items = this.props.records.map((_, recordIndex) => {
                const item = this.state.dataItems.find(
                    (item) =>
                        item.recordIndex === recordIndex &&
                        (matchDatasetVersion
                            ? item.recordDetail.dataset.displayFullName ===
                              datasetName
                            : item.recordDetail.dataset.displayName ===
                              datasetName)
                );

                return item;
            });

            const categories = Array.from(
                new Set(
                    items.flatMap((item) => {
                        const wers = item?.metrics.category_wer;
                        return wers ? wers.map((wer) => wer.category) : [];
                    })
                )
            );

            const groupData = categories.map((category) => {
                // retrieve wer data
                const werItems = items.map((item) => {
                    let wer: OcrWerResult | undefined;
                    if (
                        item &&
                        item.metrics &&
                        item.metrics.category_wer &&
                        item.metrics.category_wer.length > 0
                    ) {
                        const { metrics, recordDetail } = item;
                        wer = metrics.category_wer.find(
                            (wer) => wer.category === category
                        );
                        if (wer) {
                            wer.datasetName = matchDatasetVersion
                                ? recordDetail.dataset.displayFullName
                                : recordDetail.dataset.displayName;
                            wer.errorCount = Math.round(
                                (wer.wordCount * wer.error) / 100
                            );
                        }
                    }

                    if (wer === undefined) {
                        wer = {
                            category: category,
                            sentenceCount: NaN,
                            wordCount: NaN,
                            correct: NaN,
                            substitueRate: NaN,
                            deleteRate: NaN,
                            insertRate: NaN,
                            error: NaN,
                            errorCount: NaN,
                            sentenceErrorRate: NaN,
                            datasetName:
                                (matchDatasetVersion
                                    ? item?.recordDetail.dataset.displayFullName
                                    : item?.recordDetail.dataset.displayName) ??
                                "",
                        };
                    }

                    return {
                        data: item,
                        wer: wer,
                    } as IWerDataItem<
                        IDataItem<OcrGeneralMetrics>,
                        OcrWerResult
                    >;
                });
                return [category, werItems] as [
                    string,
                    IWerDataItem<IDataItem<OcrGeneralMetrics>, OcrWerResult>[]
                ];
            });

            return [datasetName, groupData] as [
                string,
                [
                    string,
                    IWerDataItem<IDataItem<OcrGeneralMetrics>, OcrWerResult>[]
                ][]
            ];
        });

        return data.filter(([_, groupData]) => groupData.length > 0);
    }

    private deepLinkHandler(key: string, linkData: any): void {
        if (this.props.setSelectedSubPivot) {
            this.props.setSelectedSubPivot(key, linkData);
        }
    }

    private _onAnalysisItemInvoked(item: StatisticalEntity): void {
        if (item && !AGGREGATED_CATEGORIES.includes(item.name)) {
            let linkData: { [key: string]: string | undefined } = {};
            linkData.toSelectLanguage = item.language;
            linkData.toSelectCategory = item.name;
            this.deepLinkHandler("ByImage", linkData);
        }
    }

    private _onItemInvoked(item: any, index?: number | undefined): void {
        const values = item as any[];
        if (values?.length >= 2) {
            const fieldMetricsArr = values[1] as OcrWerResult[];
            if (fieldMetricsArr?.length > 0) {
                let linkData: { [key: string]: string | undefined } = {};
                linkData.toSelectLanguage = fieldMetricsArr[0].datasetName;
                linkData.toSelectCategory = fieldMetricsArr[0].category;
                if (
                    AGGREGATED_CATEGORIES.indexOf(linkData.toSelectCategory) ===
                    -1
                ) {
                    this.deepLinkHandler!("ByImage", linkData);
                }
            }
        }
    }
}
